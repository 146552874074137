import { useState } from "react";

import { Button, Field, Fields, Input, Modal } from "@m/ui";

import { useCreateNewRelicAccount } from "../api";

interface CreateNewRelicAccountModalProps {
  companySlug: string;
  onClose: () => void;
  open: boolean;
}

export const CreateNewRelicAccountModal = ({
  companySlug,
  onClose,
  open,
}: CreateNewRelicAccountModalProps) => {
  const [createNewRelicAccount, { loading }] =
    useCreateNewRelicAccount(companySlug);

  const [name, setName] = useState("");

  const handleCreateNewRelicAccount = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createNewRelicAccount(name).then(onClose);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      header={<Modal.Title>Create Account</Modal.Title>}
    >
      <Modal.Description className="text-sm font-medium text-subdued">
        Create and name a New Relic account.
      </Modal.Description>
      <form onSubmit={handleCreateNewRelicAccount}>
        <Fields className="my-2">
          <Field label="Name" htmlFor="name">
            <Input
              id="name"
              minLength={3}
              onChange={(e) => setName(e.target.value)}
              placeholder="e.g. Account 01"
              required
              value={name}
            />
          </Field>
        </Fields>
        <Modal.Actions>
          <Button kind="primary" loading={loading} type="submit">
            Create
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};

import { useParams } from "react-router-dom";

import { Button, useModalState } from "@m/ui";

import { PageTitle } from "@atlas/components";

import {
  CreateNewRelicAccountModal,
  NewRelicAccountsTable,
} from "../components";

export const CustomerNewRelicAccountsPage = () => {
  const { companySlug } = useParams<{ companySlug: string }>();

  const createAccountModal = useModalState();

  return (
    <main className="flex flex-col gap-3">
      <PageTitle
        title="New Relic Accounts"
        actions={
          <Button kind="primary" size="small" onClick={createAccountModal.open}>
            Create
          </Button>
        }
      />
      <NewRelicAccountsTable companySlug={companySlug} />

      <CreateNewRelicAccountModal
        companySlug={companySlug}
        onClose={createAccountModal.close}
        open={createAccountModal.isOpen}
      />
    </main>
  );
};

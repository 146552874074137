export const downloadFile = (url: string, name?: string, type?: string) => {
  if (
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_STORYBOOK_SERVICES_API
  ) {
    // Prevent navigation in storybook
    console.log({ url, name, type });
    return;
  }

  const a = document.createElement("a");
  a.href = url;
  a.download = name || "download";
  a.type = type;
  a.click();
};

import clsx from "clsx";
import { ReactNode } from "react";

interface SidebarGroupProps {
  children: ReactNode;
  title?: string;
}

export const SidebarGroup = ({ children, title }: SidebarGroupProps) => {
  return (
    <div>
      {title && (
        <div
          className={clsx(
            "mb-0.5 flex w-full items-center px-1 text-xs font-semibold tracking-wider text-subdued"
          )}
        >
          <span className="grow">{title}</span>
        </div>
      )}
      {children}
    </div>
  );
};

import clsx from "clsx";

interface TableBodyProps {
  getTableBodyProps: () => any;
  prepareRow: (row: any) => void;
  rows: any[];
  tableTheme?: string;
  wrapRowText?: boolean;
}

export const TableBody = ({
  getTableBodyProps,
  prepareRow,
  rows,
  wrapRowText,
}: TableBodyProps) => (
  <tbody {...getTableBodyProps()}>
    {rows.map((row, rowIndex) => {
      prepareRow(row);
      const { cells, getRowProps, original } = row;
      const onRowClick = original?.onClick;

      return (
        <tr
          {...getRowProps([
            {
              className: clsx("border-t first:border-none", {
                "cursor-pointer hover:bg-gray-50": !!onRowClick,
              }),
            },
          ])}
          key={`table-row-${rowIndex}`}
          onClick={onRowClick}
        >
          {cells.map((cell, cellIndex) => (
            <td
              {...cell.getCellProps([
                {
                  className: clsx(
                    "text-xs font-regular text-subdued p-1.5",
                    cell.column.cellClasses,
                    {
                      "whitespace-nowrap": !wrapRowText,
                      "text-right": cell.column.align === "right",
                      "text-center": cell.column.align === "center",
                    }
                  ),
                  width: cell.column.width,
                },
              ])}
              key={`table-row-${rowIndex}-cell-${cellIndex}`}
            >
              {cell.render("Cell")}
            </td>
          ))}
        </tr>
      );
    })}
  </tbody>
);

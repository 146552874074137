import { useLazyQuery } from "@apollo/client";

import { gql } from "@m/api/v4";

export const CAN_REGISTER_AWS_ACCOUNT_ID = gql(/* GraphQL */ `
  query useCanRegisterAwsAccountId($id: String!, $companyId: ID!) {
    canRegisterAwsAccountId(id: $id, companyId: $companyId)
  }
`);

export const useCanRegisterAwsAccountId = () => {
  const [fetchQuery, { refetch, called, ...result }] = useLazyQuery(
    CAN_REGISTER_AWS_ACCOUNT_ID,
    { notifyOnNetworkStatusChange: true }
  );

  const validateAccountId = async (id: string, companyId: string) => {
    if (called) {
      const { data } = await refetch({ id, companyId });
      return data.canRegisterAwsAccountId;
    } else {
      const { data } = await fetchQuery({ variables: { id, companyId } });
      return data.canRegisterAwsAccountId;
    }
  };

  return [validateAccountId, result] as const;
};

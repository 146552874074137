import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

export const SYNC_NEW_RELIC_ACCOUNT = gql(/* GraphQL */ `
  mutation SyncNewRelicAccount($input: SyncNewRelicAccountInput!) {
    syncNewRelicAccount(input: $input) {
      ok
      message
    }
  }
`);

export const useSyncNewRelicAccount = (accountId: string) => {
  return useMutation(SYNC_NEW_RELIC_ACCOUNT, {
    variables: { input: { accountId } },
    onError: () => toast.error("Unable to sync New Relic account."),
    onCompleted: (data) => {
      if (data.syncNewRelicAccount.ok) {
        toast.success(data.syncNewRelicAccount.message);
      } else {
        toast.error(data.syncNewRelicAccount.message);
      }
    },
  });
};

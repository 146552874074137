import { Float, FloatProps } from "@headlessui-float/react";
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ReactNode } from "react";

const DefaultTrigger = () => (
  <EllipsisVerticalIcon className="h-2.5 w-2.5 rounded-md text-action hover:bg-gray-100" />
);

export interface DropdownProps {
  /** Dropdown Menu Content */
  children: ReactNode;

  /** Additional classes */
  className?: string;

  /** Dropdown position from trigger */
  direction?: FloatProps["placement"];

  /** Change the placement of the Dropdown to keep it in view. See docs: https://floating-ui.com/docs/flip  */
  flip?: FloatProps["flip"];

  /** Trigger */
  trigger?: ReactNode;

  /** Additional props */
  [propName: string]: any;
}

export const Dropdown = ({
  className,
  children,
  direction = "bottom-start",
  flip = true,
  trigger = <DefaultTrigger />,
  ...props
}: DropdownProps) => {
  const itemsClasses = clsx(
    className,
    "dropdown-shadow rounded-md bg-white p-0.5 outline-none"
  );
  return (
    <Menu>
      <Float
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        flip={flip}
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        offset={8}
        placement={direction}
        portal={true}
      >
        <Menu.Button as="div" className="cursor-pointer" role="button">
          {trigger}
        </Menu.Button>
        <Menu.Items
          data-component="Dropdown"
          className={itemsClasses}
          {...props}
        >
          {children}
        </Menu.Items>
      </Float>
    </Menu>
  );
};

import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

export const UNLINK_COMPANY_ACCESS_HUB = gql(/* GraphQL */ `
  mutation UnlinkCompanyAccessHub($input: UnlinkCompanyAccessHubInput!) {
    unlinkCompanyAccessHub(input: $input) {
      ok
      message
    }
  }
`);

export const useUnlinkCompanyAccessHub = (companySlug: string) => {
  const [mutate, { data, ...result }] = useMutation(UNLINK_COMPANY_ACCESS_HUB, {
    onCompleted: () =>
      toast.success("The access hub application was unlinked successfully."),
    onError: () => toast.error("Unable to unlink the access hub application."),
    refetchQueries: ["GetCompanyAccessHub"],
    awaitRefetchQueries: true,
  });

  const unlinkCompanyAccessHub = () =>
    mutate({ variables: { input: { companySlug } } });

  const accessHubUnlinked = data?.unlinkCompanyAccessHub.ok;

  return [
    unlinkCompanyAccessHub,
    { data: { accessHubUnlinked }, ...result },
  ] as const;
};

import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

export const DISABLE_MARKETPLACE_METERING = gql(/* GraphQL */ `
  mutation disableMarketplaceMetering(
    $input: DisableMarketplaceMeteringInput!
  ) {
    disableMarketplaceMetering(input: $input) {
      ok
      message
    }
  }
`);

export const useDisableMarketplaceMetering = (customerIdentifier: string) =>
  useMutation(DISABLE_MARKETPLACE_METERING, {
    variables: { input: { customerIdentifier } },
    refetchQueries: ["GetCustomerSubscriptionDetails"],
    onError: () => {
      toast.error(
        "There was a problem disabling Marketplace Metering for this subscription."
      );
    },
  });

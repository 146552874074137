import { useMemo, useState } from "react";
import { useQueryParams } from "use-query-params";

import {
  AggregateBillingPeriod,
  GetAggregateMeteredChargesDailyQuery,
  GetAggregateMeteredChargesMonthlyQuery,
  GetMarketplaceMeteredChargesQuery,
  MarketplaceMeteringMethod,
  MarketplaceMeteringStatus,
} from "@m/api/v4/types";
import { Button, FilterSelect, Tabs, useModalState } from "@m/ui";
import { toProperCase } from "@m/utils";

import { SubscriptionDetails } from "../api";
import { METERED_CHARGES_QUERY_PARAMS, PERIOD_TABS } from "../constants";

import { AddMeteredChargeModal } from "./AddMeteredChargeModal";
import { MeteredChargeDatePicker } from "./MeteredChargesDatePicker";
import { MeteredChargesTableActions } from "./MeteredChargesTableActions";
import { MeteredChargesTableDaily } from "./MeteredChargesTableDaily";
import { MeteredChargesTableHourly } from "./MeteredChargesTableHourly";
import { MeteredChargesTableMonthly } from "./MeteredChargesTableMonthly";
import { StopMeteringModal } from "./StopMeteringModal";

interface MeteredChargesTableProps {
  subscriptionDetails: SubscriptionDetails;
}

export const MeteredChargesTable = ({
  subscriptionDetails,
}: MeteredChargesTableProps) => {
  const [query, setQuery] = useQueryParams(METERED_CHARGES_QUERY_PARAMS);
  const [loading, setLoading] = useState(true);

  const marketplaceSignupToken = subscriptionDetails?.signupToken;
  const meteringStatus = subscriptionDetails?.meteringStatus;

  const dimensions = useMemo(
    () => subscriptionDetails?.catalogItem?.dimensions ?? [],
    [subscriptionDetails]
  );

  const manualDimensions = useMemo(
    () =>
      dimensions.filter(
        (dimension) =>
          dimension.meteringMethod === MarketplaceMeteringMethod.Manual
      ),
    [dimensions]
  );

  const addMeteredChargeModal = useModalState();
  const exportMeteredChargesModal = useModalState();
  const stopMeteringModal = useModalState();

  const dimensionFilterOptions = dimensions.map((dimension) => ({
    id: dimension?.dimensionTypeId,
    label: dimension?.atlasName,
  })) as [];

  const canAddMeteredCharge = manualDimensions.length > 0;
  const canStopMetering = meteringStatus === MarketplaceMeteringStatus.Active;
  const canExportMeteredCharges = false;

  const filtersActive = !!(
    query.chargeDateStart ||
    query.chargeDateEnd ||
    query.reportedDateStart ||
    query.reportedDateEnd ||
    query.dimensions
  );

  const handleClearFilters = () => {
    setQuery({
      dimensions: undefined,
      reportedDateStart: undefined,
      reportedDateEnd: undefined,
      chargeDateStart: undefined,
      chargeDateEnd: undefined,
    });
  };

  const handleDimensionsFilterChange = (selectedDimensions: string[]) => {
    setQuery({
      dimensions: selectedDimensions,
    });
  };

  const handleClearDimensionsFilter = () => {
    setQuery({
      dimensions: undefined,
    });
  };

  const handlePeriodFilterChange = (period: string | undefined) => {
    // Switching period view will trigger a new aggregate query
    // So we need to wait for the query to resolve
    setLoading(true);
    if (period !== AggregateBillingPeriod.Hourly) {
      // Do not filter by reported date if not in hourly view
      setQuery({
        reportedDateStart: undefined,
        reportedDateEnd: undefined,
      });
    }
    setQuery({
      period: period
        ? (period.toUpperCase() as AggregateBillingPeriod)
        : undefined,
    });
  };

  const handleSortChange = (sort: string | undefined) => {
    setQuery({
      sort: sort,
    });
  };

  const handleReportedDateChange = ([newStartDate, newEndDate]: [
    Date,
    Date
  ]) => {
    setQuery({ reportedDateStart: newStartDate ?? undefined });
    setQuery({ reportedDateEnd: newEndDate ?? undefined });
  };

  const handleChargeDateChange = ([newStartDate, newEndDate]: [Date, Date]) => {
    setQuery({ chargeDateStart: newStartDate ?? undefined });
    setQuery({ chargeDateEnd: newEndDate ?? undefined });
  };

  const handleQueryCompleted = (
    data:
      | GetMarketplaceMeteredChargesQuery
      | GetAggregateMeteredChargesDailyQuery
      | GetAggregateMeteredChargesMonthlyQuery
  ) => {
    if (data) setLoading(false);
  };

  if (!marketplaceSignupToken) return null;

  return (
    <div className="flex flex-col gap-3">
      <div
        className="flex flex-wrap items-center gap-2"
        data-testid="filter-bar"
      >
        <MeteredChargeDatePicker
          placeholder="Charge Date"
          cadence={query.period}
          onChange={handleChargeDateChange}
          startDate={query.chargeDateStart}
          endDate={query.chargeDateEnd}
          disabled={query.period !== AggregateBillingPeriod.Hourly}
        />
        <MeteredChargeDatePicker
          placeholder="Reported Date"
          cadence={query.period}
          onChange={handleReportedDateChange}
          startDate={query.reportedDateStart}
          endDate={query.reportedDateEnd}
          disabled={query.period !== AggregateBillingPeriod.Hourly}
        />
        <FilterSelect
          initialValue="Dimensions"
          onChange={handleDimensionsFilterChange}
          onClear={handleClearDimensionsFilter}
          selection={query.dimensions || []}
          options={dimensionFilterOptions}
          multiple={true}
        />
        {filtersActive && (
          <Button onClick={handleClearFilters} fill="none" size="small">
            Reset Filters
          </Button>
        )}
        <div className="ml-auto">
          <Tabs
            selectedTab={toProperCase(query.period)}
            onTabChange={handlePeriodFilterChange}
            tabs={PERIOD_TABS || []}
          />
        </div>
        <MeteredChargesTableActions
          canAddMeteredCharge={canAddMeteredCharge}
          canExportMeteredCharges={canExportMeteredCharges}
          canStopMetering={canStopMetering}
          disabled={loading}
          onAddMeteredChargeClick={addMeteredChargeModal.open}
          onExportMeteredChargesClick={exportMeteredChargesModal.open}
          onStopMeteringClick={stopMeteringModal.open}
        />
      </div>

      {query.period === AggregateBillingPeriod.Daily && (
        <MeteredChargesTableDaily
          marketplaceSignupToken={marketplaceSignupToken}
          onSortChange={handleSortChange}
          onQueryCompleted={handleQueryCompleted}
        />
      )}
      {query.period === AggregateBillingPeriod.Hourly && (
        <MeteredChargesTableHourly
          marketplaceSignupToken={marketplaceSignupToken}
          onSortChange={handleSortChange}
          onCompleted={handleQueryCompleted}
        />
      )}
      {query.period === AggregateBillingPeriod.Monthly && (
        <MeteredChargesTableMonthly
          marketplaceSignupToken={marketplaceSignupToken}
          onSortChange={handleSortChange}
          onQueryCompleted={handleQueryCompleted}
        />
      )}

      <AddMeteredChargeModal
        isOpen={addMeteredChargeModal.isOpen}
        onClose={addMeteredChargeModal.close}
        subscriptions={[
          {
            marketplaceProductCode: subscriptionDetails?.marketplaceProductCode,
            customerIdentifier: subscriptionDetails?.customerIdentifier,
            displayName: subscriptionDetails?.catalogItem?.displayName,
          },
        ]}
      />
      <StopMeteringModal
        customerIdentifier={subscriptionDetails?.customerIdentifier ?? ""}
        isOpen={stopMeteringModal.isOpen}
        onClose={stopMeteringModal.close}
      />
    </div>
  );
};

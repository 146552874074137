import {
  BuildingOfficeIcon,
  ClockIcon,
  IdentificationIcon,
  KeyIcon,
  RocketLaunchIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useRef } from "react";
import { generatePath } from "react-router-dom";

import {
  Button,
  Buttons,
  Card,
  Confirm,
  CopyButton,
  Field,
  Input,
  Link,
  Section,
  Suspensed,
  useModalState,
} from "@m/ui";
import { formatFullDate, toHyphenLowercase } from "@m/utils";

import { PATHS } from "@atlas/constants";

import {
  useCompanyAccessHub,
  useFindCompanyAccessHub,
  useLinkCompanyAccessHub,
  useUnlinkCompanyAccessHub,
} from "../api";

interface OktaAccessHubSectionProps {
  companySlug: string;
}

export const OktaAccessHubSection = ({
  companySlug,
}: OktaAccessHubSectionProps) => {
  const oktaAppIdRef = useRef<HTMLInputElement>(null);

  const unlinkConfirmationDialog = useModalState();

  const {
    data: { linkedAccessHub },
    loading: linkedAccessHubLoading,
  } = useCompanyAccessHub(companySlug);

  const [
    findCompanyAccessHub,
    {
      data: { companyAccessHub },
      loading: findCompanyAccessHubLoading,
      reset: resetFindCompanyAccessHub,
    },
  ] = useFindCompanyAccessHub();

  const [linkCompanyAccessHub, { loading: linkCompanyAccessHubLoading }] =
    useLinkCompanyAccessHub(companySlug);

  const [unlinkCompanyAccessHub, { loading: unlinkCompanyAccessHubLoading }] =
    useUnlinkCompanyAccessHub(companySlug);

  const handleFindCompanyAccessHub = () => {
    const oktaAppId = oktaAppIdRef.current?.value;
    if (oktaAppId) findCompanyAccessHub(oktaAppId);
  };

  const handleLinkCompanyAccessHub = () => {
    if (companyAccessHub)
      linkCompanyAccessHub(companyAccessHub.appId).then(() =>
        resetFindCompanyAccessHub()
      );
  };

  const handleUnlinkCompanyAccessHub = () =>
    unlinkCompanyAccessHub().then(() => unlinkConfirmationDialog.close());

  const findCompanyAccessHubError =
    companyAccessHub === null
      ? "No Okta App found with that ID"
      : companyAccessHub?.company
      ? `This Okta App is already linked to ${companyAccessHub.company.name}`
      : companyAccessHub?.status === "INACTIVE"
      ? "This Okta App is inactive"
      : undefined;

  const bootstrapURL = `https://bootstrap.missioncloud.com/ps/?descriptor=${companySlug}&id=${linkedAccessHub?.awsAccountId}`;

  return (
    <Card>
      <Section
        title="Okta Access Hub"
        description={
          linkedAccessHub
            ? "This customer is linked to an Okta Access Hub Application. To link a different access hub, unlink this one first."
            : "Link this customer to an Okta Access Hub Application"
        }
      >
        {!linkedAccessHub && (
          <Suspensed
            loading={linkedAccessHubLoading}
            height="5rem"
            width="400px"
          >
            <Field
              label="Okta App ID"
              htmlFor="okta-app-id"
              error={findCompanyAccessHubError}
            >
              <div className="flex items-center gap-2">
                <Input
                  id="okta-app-id"
                  ref={oktaAppIdRef}
                  className="min-w-[300px]"
                  required
                />
                <Button
                  fill="subdued"
                  kind="primary"
                  loading={findCompanyAccessHubLoading}
                  onClick={handleFindCompanyAccessHub}
                >
                  Find App
                </Button>
              </div>
            </Field>
          </Suspensed>
        )}

        {(linkedAccessHub ?? companyAccessHub) && (
          <Field label="Information">
            <InformationItem
              icon={KeyIcon}
              label="Okta Label"
              value={linkedAccessHub?.label ?? companyAccessHub.label}
              allowCopy
            />
            <InformationItem
              icon={IdentificationIcon}
              label="Okta App ID"
              value={linkedAccessHub?.appId ?? companyAccessHub.appId}
              allowCopy
            />
            <InformationItem
              icon={ClockIcon}
              label="Last Updated"
              value={formatFullDate(
                linkedAccessHub?.lastUpdated ?? companyAccessHub.lastUpdated
              )}
            />
            {linkedAccessHub && (
              <InformationItem
                icon={RocketLaunchIcon}
                label="Bootstrap URL"
                value={
                  <>
                    <Link to={bootstrapURL}>{bootstrapURL}</Link>{" "}
                    <CopyButton text={bootstrapURL} />
                  </>
                }
              />
            )}
            {!linkedAccessHub && companyAccessHub?.company && (
              <InformationItem
                icon={BuildingOfficeIcon}
                label="Company"
                value={
                  <Link
                    to={generatePath(PATHS.CUSTOMER_PROFESSIONAL_SERVICES, {
                      companySlug: companyAccessHub.company.slug,
                    })}
                  >
                    {companyAccessHub.company.name}
                  </Link>
                }
              />
            )}
          </Field>
        )}

        {!linkedAccessHubLoading && (
          <Buttons align="right">
            {!linkedAccessHub && (
              <Button
                disabled={!companyAccessHub || !!findCompanyAccessHubError}
                kind="primary"
                loading={linkCompanyAccessHubLoading}
                onClick={handleLinkCompanyAccessHub}
              >
                Link
              </Button>
            )}
            {linkedAccessHub && (
              <Button
                kind="destructive"
                fill="none"
                leftIcon={TrashIcon}
                onClick={unlinkConfirmationDialog.open}
              >
                Unlink
              </Button>
            )}
          </Buttons>
        )}
      </Section>

      <Confirm
        data-testid="unlink-confirmation-dialog"
        onClose={unlinkConfirmationDialog.close}
        open={unlinkConfirmationDialog.isOpen}
        actions={
          <Buttons>
            <Button fill="none" onClick={unlinkConfirmationDialog.close}>
              Cancel
            </Button>
            <Button
              kind="destructive"
              loading={unlinkCompanyAccessHubLoading}
              onClick={handleUnlinkCompanyAccessHub}
            >
              Unlink
            </Button>
          </Buttons>
        }
      >
        Are you sure you want to unlink this Okta Access Hub?
      </Confirm>
    </Card>
  );
};

const InformationItem = ({ label, value, icon: Icon, allowCopy = false }) => {
  const id = "information-item-" + toHyphenLowercase(label);
  return (
    <div className="flex items-center gap-1 text-sm font-medium text-subdued">
      {Icon && <Icon className="h-2.5 w-2.5" />}
      <div id={id}>{label}:</div>
      <div aria-labelledby={id}>{value}</div>
      {allowCopy && <CopyButton text={value} />}
    </div>
  );
};

import { AccessRequired } from "@m/api/components";

import { SidebarGroup, SidebarLink } from "@atlas/components/AtlasSidebar";
import { ATLAS_FEATURE_FLAGS } from "@atlas/constants";

export const CustomerDetailsSidebar = () => {
  return (
    <nav data-testid="sidebar" className="mr-3 min-w-[160px]">
      <menu className="flex flex-col gap-3">
        <SidebarGroup title="Details">
          <SidebarLink title="Overview" to="./overview" />
          <SidebarLink title="Subscriptions" to="./subscriptions" />
          <SidebarLink
            title="Professional Services"
            to="./professional-services"
          />
        </SidebarGroup>

        <AccessRequired feature={ATLAS_FEATURE_FLAGS.RELEASE_PORTAL_REDESIGN}>
          <SidebarGroup title="Managed Services">
            <SidebarLink title="New Relic" to="./new-relic" />
          </SidebarGroup>
        </AccessRequired>
      </menu>
    </nav>
  );
};

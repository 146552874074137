import * as Sentry from "@sentry/react";

import { UserSession } from "@m/types";

import { LOGIN_ERROR } from "../constants";

import { saveToLocalStorage } from "./saveToLocalStorage";

export const establishLoginSession = (
  token: string,
  signal: AbortSignal
): Promise<UserSession> => {
  return new Promise((resolve, reject) => {
    const servicesApiUrl = process.env.REACT_APP_SERVICES_API;
    if (!servicesApiUrl) return reject(null);
    fetch(servicesApiUrl.concat("/api/public/v1/login"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ token }),
      signal,
    })
      .then((response) => {
        if (response.status !== 200) {
          if (
            response.status === 503 &&
            response.headers.has("X-Mission-Maintenance")
          ) {
            throw new Error("X-Mission-Maintenance");
          } else if (response.status === 402) {
            throw 402;
          } else {
            throw new Error(LOGIN_ERROR);
          }
        }
        return response.json();
      })
      .then((session) => {
        const company = session.current_company;
        delete session.current_company;
        const user = saveToLocalStorage({
          company,
          ...session,
        });
        return user ? resolve(user) : reject(null);
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          if (e == 402) return reject(e);
          reject(e ? e.message : LOGIN_ERROR);
          Sentry.captureException(e);
        }
      });
  });
};

import clsx from "clsx";
import { useEffect, useMemo } from "react";
import Overflow from "react-overflow-indicator";
import {
  TableOptions,
  UseRowSelectOptions,
  useRowSelect,
  useTable,
} from "react-table";

import { EmptyMessage } from "@m/ui";

import style from "./Table.module.css";
import { TableBody, TableFooter, TableHeader } from "./components";
import {
  DEFAULT_COLUMN,
  ICON_COLUMN,
  SELECT_COLUMN,
  TABLE_MESSAGES,
  TABLE_TITLES,
} from "./constants";
import { useSelectAllCheckbox, useSelectRowCheckbox } from "./hooks";
import { createPlaceholders, mapHeaders } from "./utils";

interface TableProps {
  // Base Props
  ariaLabel?: string;
  emptyMessage?: string;
  headers: any[];
  headerFontSize?: "default" | "small";
  id?: string;
  loading?: boolean;
  rows: any[];
  showIcons?: boolean;
  showHeader?: boolean;
  wrapRowText?: boolean;

  // Sorting Props
  defaultSort?: string;
  onSortChange?: (sort: string) => void;

  // Filter Props
  clearFilters?: () => void;
  hasActiveFilters?: boolean;
  clearFiltersButtonText?: string;

  // Pagination Props
  currentPage?: number;
  fetchMore?: (...args: any) => Promise<object>;
  pageInfo?: any;
  pageSize?: number;
  setCurrentPage?: (page: number) => void;
  showPagination?: boolean;
  totalCount?: number;

  // Row Select Props
  isAllRowsSelected?: boolean;
  manualRowIdKey?: string;
  selectedRowIds?: string[];
  showSelectAll?: boolean;
  toggleAllRowsSelected?: (selected: boolean) => void;
  toggleAllRowsSelectedTitle?: string;
  toggleRowSelected?: (id: string, checked: boolean) => void;
  toggleRowSelectedTitle?: string;
}

export const Table = ({
  // Base Props
  ariaLabel,
  emptyMessage = TABLE_MESSAGES.DEFAULT_EMPTY_MESSAGE,
  headers,
  headerFontSize = "default",
  id,
  loading = false,
  rows,
  showIcons = false,
  showHeader = true,
  wrapRowText = false,

  // Sorting Props
  defaultSort,
  onSortChange,

  // Filter Props
  clearFilters,
  hasActiveFilters = false,
  clearFiltersButtonText = "",

  // Pagination Props
  currentPage,
  fetchMore,
  pageInfo,
  pageSize,
  setCurrentPage,
  showPagination = false,
  totalCount,

  // Row Select Props
  isAllRowsSelected,
  manualRowIdKey = "id",
  selectedRowIds,
  showSelectAll = false,
  toggleAllRowsSelected,
  toggleAllRowsSelectedTitle = TABLE_TITLES.TOGGLE_ALL_ROWS_SELECTED,
  toggleRowSelected,
  toggleRowSelectedTitle = TABLE_TITLES.TOGGLE_ROW_SELECTED,
}: TableProps) => {
  const empty = rows.length === 0;

  const columns = useMemo(() => mapHeaders(headers), [headers]);

  const data = useMemo(() => {
    if (empty || loading) return createPlaceholders(columns, loading);
    else return rows;
  }, [rows, columns, loading, empty]);

  const initialState = useMemo(
    () => ({
      hiddenColumns: [
        ...(showSelectAll && !empty ? [] : [SELECT_COLUMN.id]),
        ...(showIcons && !empty ? [] : [ICON_COLUMN.id]),
      ],
    }),
    [showSelectAll, showIcons, empty]
  );

  const { renderSelectAllCheckbox } = useSelectAllCheckbox({
    isAllRowsSelected,
    selectedRowIds,
    toggleAllRowsSelected,
    toggleAllRowsSelectedTitle,
  });

  const { renderSelectRowCheckbox } = useSelectRowCheckbox({
    isAllRowsSelected,
    manualRowIdKey,
    selectedRowIds,
    toggleRowSelected,
    toggleRowSelectedTitle,
  });

  const totalSelected = selectedRowIds?.length;

  useEffect(() => {
    if (!showSelectAll) return;
    if (totalCount < 1) return; // If no rows, don't toggle anything.
    if (totalSelected === totalCount) toggleAllRowsSelected(!isAllRowsSelected);
  }, [
    isAllRowsSelected,
    showSelectAll,
    toggleAllRowsSelected,
    totalCount,
    totalSelected,
  ]);

  const table = useTable(
    {
      autoResetSelectedRows: false,
      columns,
      data,
      defaultColumn: DEFAULT_COLUMN,
      initialState,
    } as TableOptions<any> & UseRowSelectOptions<any>,
    useRowSelect,
    (hooks) => {
      if (!loading)
        hooks.visibleColumns.push((columns) => [
          {
            ...SELECT_COLUMN,
            Header: renderSelectAllCheckbox as any,
            Cell: renderSelectRowCheckbox as any,
          },
          ICON_COLUMN,
          ...columns,
        ]);
    }
  );

  const showFooter = !empty && !loading && showPagination;

  return (
    <div className="relative">
      <Overflow className="max-w-full overflow-x-auto">
        <Overflow.Content className="align-top">
          <table
            {...(id && { id })}
            {...table.getTableProps([
              {
                className: "text-left w-full",
              },
            ])}
            aria-busy={loading}
            aria-label={ariaLabel}
            aria-live="polite"
          >
            {showHeader && (
              <TableHeader
                defaultSort={defaultSort}
                empty={empty}
                headerFontSize={headerFontSize}
                headerGroups={table.headerGroups}
                onSortChange={onSortChange}
              />
            )}

            <TableBody
              getTableBodyProps={table.getTableBodyProps}
              prepareRow={table.prepareRow}
              rows={table.rows}
              wrapRowText={wrapRowText}
            />
          </table>
        </Overflow.Content>
        <Overflow.Indicator direction="right">
          <span className={clsx(style.shadow, style.shadowRight)} />
        </Overflow.Indicator>
        <Overflow.Indicator direction="left">
          <span className={clsx(style.shadow, style.shadowLeft)} />
        </Overflow.Indicator>
      </Overflow>

      {showFooter && (
        <TableFooter
          currentPage={currentPage}
          fetchMore={fetchMore}
          pageInfo={pageInfo}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
        />
      )}

      {empty && !loading && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
          <EmptyMessage
            buttonAction={clearFilters}
            buttonText={
              clearFiltersButtonText
                ? clearFiltersButtonText
                : hasActiveFilters
                ? "Reset Filters"
                : ""
            }
            message={emptyMessage}
          />
        </div>
      )}
    </div>
  );
};

import { Navigate } from "react-router-dom";

import { PATHS } from "@atlas/constants";

export const settingsRoutes = [
  {
    index: true,
    element: <Navigate to="access-tokens" replace />,
  },
  {
    path: "access-tokens",
    handle: {
      crumb: () => <span>Access Tokens</span>,
      to: PATHS.SETTINGS_ACCESS_TOKENS,
    },
    element: <>Access Tokens</>,
  },
  {
    path: "alert-conditions",
    handle: {
      crumb: () => <span>Alert Conditions</span>,
      to: PATHS.SETTINGS_ALERT_CONDITIONS,
    },
    element: <>Alert Conditions</>,
  },
  {
    path: "dashboards",
    handle: {
      crumb: () => <span>Dashboards</span>,
      to: PATHS.SETTINGS_DASHBOARDS,
    },
    element: <>Dashboards</>,
  },
];

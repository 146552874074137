import { PropsWithChildren } from "react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { Auth0Provider, AuthProvider } from "@m/login";
import { UIProvider } from "@m/ui";

export const GlobalProviders = ({ children }: PropsWithChildren) => (
  <Auth0Provider clientId={process.env.REACT_ATLAS_APP_AUTH0_CLIENT_ID}>
    <UIProvider>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{ removeDefaultsFromUrl: true }}
      >
        <AuthProvider>{children}</AuthProvider>
      </QueryParamProvider>
    </UIProvider>
  </Auth0Provider>
);

import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

export const TERMINATE_NEW_RELIC_ACCOUNT = gql(/* GraphQL */ `
  mutation TerminateNewRelicAccount($input: CancelNewRelicAccountInput!) {
    cancelNewRelicAccount(input: $input) {
      ok
      message
    }
  }
`);

export const useTerminateNewRelicAccount = (accountId: string) => {
  return useMutation(TERMINATE_NEW_RELIC_ACCOUNT, {
    variables: { input: { accountId } },
    onError: () => toast.error("Unable to terminate New Relic account."),
    onCompleted: (data) => {
      if (data.cancelNewRelicAccount.ok) {
        toast.success("New Relic account terminated successfully.");
      } else {
        toast.error(data.cancelNewRelicAccount.message);
      }
    },
  });
};

import { PageTitle } from "@atlas/components";

import { CustomerSubscriptionsTable } from "../components";

export const CustomerSubscriptionsPage = () => {
  return (
    <main className="flex flex-col gap-2">
      <PageTitle title="Current Subscriptions" />
      <CustomerSubscriptionsTable />
    </main>
  );
};

import { CommandLineIcon } from "@heroicons/react/24/outline";

import { Badge, CopyButton, Field, Modal, Tooltip } from "@m/ui/components";
import { toProperCase } from "@m/utils";

import { AwsAccount } from "../api";

import { AwsAccountSyncStatus } from "./AwsAccountSyncStatus";

const PROSERV_ROLENAME = "roleName=MissionProfessionalServicesEngineer";

interface AwsAccountDetailsModalProps {
  onClose: () => void;
  open?: boolean;
  awsAccount: AwsAccount;
}

export const AwsAccountDetailsModal = ({
  onClose,
  open = true,
  awsAccount,
}: AwsAccountDetailsModalProps) => {
  const {
    accountId,
    name,
    disabled,
    email,
    externalId,
    isSyncing,
    lastCompletedSynced,
    payerAccountId,
    supportPlan,
    syncDisableReason,
    syncsEnabled,
    supportsCrossAccountAuth,
    signinUrl,
  } = awsAccount || {};

  const authProserv = signinUrl?.includes(PROSERV_ROLENAME);
  const authCommand = `missionctl auth ${
    authProserv ? "--proserv " : " "
  }-a ${accountId} --profile-name "${name || accountId}"`;

  return (
    <Modal
      open={open}
      onClose={onClose}
      header={<Modal.Title>Account Details</Modal.Title>}
      size="2xl"
    >
      <Modal.Description className="flex flex-col gap-2 pt-2">
        <Field
          label="Mission API Account Status"
          labelId="mission-api-account-status"
          row
        >
          <div className="flex justify-end">
            <Badge
              aria-labelledby="mission-api-account-status"
              label={disabled ? "Disabled" : "Active"}
              status={disabled ? "negative" : "positive"}
            />
          </div>
        </Field>
        <Field
          label="Mission API Account Sync Status"
          labelId="mission-api-account-sync-status"
          row
        >
          <div className="flex justify-end">
            <AwsAccountSyncStatus
              aria-labelledby="mission-api-account-sync-status"
              isSyncing={isSyncing}
              lastCompletedSynced={lastCompletedSynced}
              syncDisableReason={syncDisableReason}
              syncsEnabled={syncsEnabled}
            />
          </div>
        </Field>
        {externalId && (
          <Field label="External ID" labelId="external-id">
            <div
              aria-labelledby="external-id"
              className="flex items-center gap-1"
            >
              <Tooltip
                asChild
                className="max-w-xs break-all"
                content={externalId}
              >
                <div className="truncate underline decoration-dashed underline-offset-2">
                  {externalId}
                </div>
              </Tooltip>
              <CopyButton text={externalId} />
            </div>
          </Field>
        )}
        <Field label="Account ID" labelId="-account-id" row>
          <div aria-labelledby="account-id" className="flex justify-end gap-1">
            {accountId}
            <CopyButton text={accountId} />
          </div>
        </Field>
        {payerAccountId && (
          <Field label="Payer Account ID" labelId="payer-account-id" row>
            <div
              aria-labelledby="payer-account-id"
              className="flex justify-end gap-1"
            >
              {payerAccountId}
              <CopyButton text={payerAccountId} />
            </div>
          </Field>
        )}
        {supportPlan && (
          <Field label="AWS Support Plan" labelId="aws-support-plan" row>
            <div
              aria-labelledby="aws-support-plan"
              className="flex justify-end"
            >
              {toProperCase(supportPlan)}
            </div>
          </Field>
        )}
        {email && (
          <Field label="Root Email Address" labelId="root-email-address" row>
            <div
              aria-labelledby="root-email-address"
              className="flex justify-end gap-1"
            >
              {email}
              <CopyButton text={email} />
            </div>
          </Field>
        )}
        <div className="mt-3 flex flex-col rounded-lg bg-gray-900 text-gray-200">
          <div className="flex flex-row rounded-lg bg-gray-800 p-2">
            <p className="basis-full">
              <CommandLineIcon className="mr-1 inline h-2 w-2" /> Terminal
            </p>
            <div>
              <Tooltip content="Copy to Clipboard">
                <CopyButton text={authCommand} inverted />
              </Tooltip>
            </div>
          </div>

          <div className="p-2 font-mono">
            <p className="opacity-70">
              # authenticate via{" "}
              {supportsCrossAccountAuth
                ? "cross-account access (via a Hub account)"
                : "Okta SSO"}
            </p>
            <p>
              <span className="pr-1">$ {authCommand}</span>
            </p>
            <p className="pt-2 opacity-70">
              {supportsCrossAccountAuth ? (
                <span>
                  [!] This account does not support Okta SSO access. Attempting
                  cross account access...
                  <br />
                  [!] Authing to Hub account...
                  <br />
                  [+] Successfully authenticated to Hub AWS account.
                  <br />
                  [+] Successfully authenticated to target AWS account{" "}
                  {accountId}
                </span>
              ) : (
                <span>
                  [!] This account supports Okta SSO. Proceeding with Okta SSO
                  access...
                  <br />
                  Auto select role as only one is available : arn:aws:iam::
                  {accountId}:role/MissionAdministrator
                  <br />
                  [+] You've successfully authenticated!
                </span>
              )}
            </p>
            <p className="pt-2">$ aws --profile "{name || accountId}" s3 ls</p>
          </div>
        </div>
      </Modal.Description>
    </Modal>
  );
};

import { ApolloLink, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { TErrorOptions } from "../types";

import { fetchWithCSVDownload } from "./csv";
import { handleGraphQLErrors, handleHTTPError } from "./errors";
import { saveExtensions } from "./extensions";

const publicHttpLink = new HttpLink({
  uri: (
    process.env.REACT_APP_STORYBOOK_SERVICES_API ||
    process.env.REACT_APP_SERVICES_API ||
    ""
  ).concat("/api/public/v1/graphql"),
  credentials: "include",
  fetch: fetchWithCSVDownload,
});

const v4HttpLink = new HttpLink({
  uri: (
    process.env.REACT_APP_STORYBOOK_SERVICES_API ||
    process.env.REACT_APP_SERVICES_API ||
    ""
  ).concat("/api/v4/graphql"),
  credentials: "include",
  fetch: fetchWithCSVDownload,
});

export const customHeadersMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(navigator.webdriver === true ? { "X-Navigator-Webdriver": "1" } : {}),
      ...(operation.getContext().filename
        ? { "X-CSV-Filename": operation.getContext().filename }
        : {}),
      ...(operation.getContext().exportFilename
        ? { "X-EXPORT-ME": operation.getContext().exportFilename }
        : {}),
      "X-CSRF-Token": localStorage.getItem("csrf") || null,
    },
  }));
  return forward(operation);
});

export const extensionsLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.extensions) {
      saveExtensions(response.extensions);
    }
    return response;
  });
});

export const makeHttpErrorLink = (
  errorOptions: TErrorOptions = {
    logout: () => {},
    onMaintenanceResponse: () => {},
  }
) => {
  const { logout, onMaintenanceResponse } = errorOptions;

  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      handleGraphQLErrors(graphQLErrors);
    }
    if (networkError) {
      handleHTTPError(networkError, logout, { onMaintenanceResponse });
    }
  });
};

export const httpLink = ApolloLink.split(
  (operation) => operation.getContext().clientName === "v4",
  v4HttpLink, // <= apollo will send to this if clientName is "v4"
  publicHttpLink // <= otherwise will send to this
);

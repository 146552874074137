import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Banner,
  Button,
  Buttons,
  Checkbox,
  Confirm,
  Field,
  Suspensed,
  useModalState,
} from "@m/ui";
import { formatDate } from "@m/utils";

import { PageTitle } from "@atlas/components";

import {
  GET_NEW_RELIC_ACCOUNT_DETAILS,
  NewRelicAccountDetails,
  NewRelicCondition,
  NewRelicConditionTemplate,
  NewRelicDashboard,
  NewRelicDashboardTemplate,
  NewRelicPolicy,
  NewRelicPolicyTemplate,
  useNewRelicAccountDetails,
  useNewRelicTemplates,
  useSyncNewRelicAccount,
  useTerminateNewRelicAccount,
} from "../api";

export const NewRelicAccountDetailsPage = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const navigate = useNavigate();

  const {
    data: { account, dashboards, conditions, policies },
    loading,
    error,
  } = useNewRelicAccountDetails(accountId);

  const {
    data: { dashboardTemplates, conditionTemplates, policyTemplates },
    loading: templatesLoading,
  } = useNewRelicTemplates();

  const [syncNewRelicAccount, { loading: syncNewRelicAccountLoading }] =
    useSyncNewRelicAccount(accountId);

  const [
    terminateNewRelicAccount,
    { loading: terminateNewRelicAccountLoading },
  ] = useTerminateNewRelicAccount(accountId);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const terminateModal = useModalState();

  const returnToAccounts = () => navigate("../");

  const handleTerminateAccount = () => {
    terminateNewRelicAccount().then(() => returnToAccounts());
  };

  useEffect(() => {
    if (error || account === null) returnToAccounts();
  }, [error, account]);

  // If any resources are present, we consider the account setup
  const isAccountSetup =
    dashboards.length > 0 || conditions.length > 0 || policies.length > 0;

  return (
    <main className="flex flex-col gap-3">
      <Suspensed loading={loading}>
        <PageTitle
          title={account?.name}
          description="Account Settings"
          actions={
            <Buttons>
              <Button
                kind="primary"
                fill="subdued"
                size="small"
                onClick={() => setIsEditing(true)}
                disabled={isEditing}
              >
                Edit
              </Button>
              <Button
                kind="primary"
                fill="subdued"
                size="small"
                to="./domain-monitors"
              >
                Domain Monitors
              </Button>
              <Button
                kind="primary"
                size="small"
                onClick={syncNewRelicAccount}
                loading={syncNewRelicAccountLoading}
              >
                Sync
              </Button>
            </Buttons>
          }
        />
      </Suspensed>

      <Suspensed loading={loading} width="30%">
        <div className="flex gap-2">
          <Field
            className="w-fit whitespace-nowrap"
            label="ID"
            labelId="account-id"
            row
          >
            <span
              aria-labelledby="account-id"
              className="text-sm font-semibold text-subdued"
            >
              {accountId}
            </span>
          </Field>
          <Field
            className="w-fit whitespace-nowrap "
            label="Last Synced"
            labelId="last-sync"
            row
          >
            <span
              aria-labelledby="last-sync"
              className="text-sm font-semibold text-subdued"
            >
              {account?.lastSynced
                ? formatDate(account.lastSynced, { longFormat: true })
                : "Never"}
            </span>
          </Field>
        </div>
      </Suspensed>

      {isEditing && (
        <Banner
          status={isAccountSetup ? "warning" : "info"}
          label={
            isAccountSetup ? (
              <>
                When automatic syncing is enabled, any resources you uncheck
                below will be removed from this account.
              </>
            ) : (
              <>
                This account needs to be configured. We've pre-selected some
                recommended defaults to help you get started. You can customize
                these settings as needed. Note that automatic syncing must be
                enabled before you can add resources to this account.
              </>
            )
          }
        />
      )}

      <Suspensed loading={loading} width="30%">
        <AutomaticSyncingForm account={account} isEditing={isEditing} />
      </Suspensed>

      <Suspensed
        loading={loading || templatesLoading}
        width="30%"
        height="5rem"
      >
        <SelectResourcesForm
          isAccountSetup={isAccountSetup}
          isEditing={isEditing}
          resourceTemplates={dashboardTemplates}
          resourceType="Dashboards"
          resources={dashboards}
        />
      </Suspensed>

      <Suspensed loading={loading} height="3rem">
        <SelectWorkFlowsForm account={account} isEditing={isEditing} />
      </Suspensed>

      <Suspensed
        loading={loading || templatesLoading}
        width="30%"
        height="5rem"
      >
        <SelectResourcesForm
          isAccountSetup={isAccountSetup}
          isEditing={isEditing}
          resourceTemplates={conditionTemplates}
          resourceType="Conditions"
          resources={conditions}
        />
      </Suspensed>

      <Suspensed
        loading={loading || templatesLoading}
        width="30%"
        height="5rem"
      >
        <SelectResourcesForm
          isAccountSetup={isAccountSetup}
          isEditing={isEditing}
          resourceTemplates={policyTemplates}
          resourceType="Policies"
          resources={policies}
        />
      </Suspensed>

      <Buttons className="justify-between">
        <Button kind="destructive" fill="subdued" onClick={terminateModal.open}>
          Terminate
        </Button>
        <Button kind="primary" fill="none" to="../">
          Go Back
        </Button>
      </Buttons>

      <Confirm
        open={terminateModal.isOpen}
        onClose={terminateModal.close}
        actions={
          <Buttons>
            <Button
              kind="primary"
              fill="none"
              size="small"
              onClick={terminateModal.close}
            >
              Cancel
            </Button>
            <Button
              kind="primary"
              onClick={handleTerminateAccount}
              size="small"
              loading={terminateNewRelicAccountLoading}
            >
              Terminate
            </Button>
          </Buttons>
        }
      >
        Are you sure you want to terminate this account?
        <br />
        <strong>This action can't be undone.</strong>
      </Confirm>
    </main>
  );
};

const AutomaticSyncingForm = ({
  account,
  isEditing,
}: {
  account: NewRelicAccountDetails;
  isEditing: boolean;
}) => {
  const isAutomaticSyncingEnabled =
    account?.syncState?.toLowerCase() === "enabled";

  const [autoSync, setAutoSync] = useState<boolean>(isAutomaticSyncingEnabled);

  return (
    <div className="flex flex-col gap-2">
      <Checkbox
        checked={autoSync}
        disabled={!isEditing}
        id="enable-auto-sync"
        label="Enable automatic syncing"
        onClick={() => setAutoSync((prev) => !prev)}
      />
    </div>
  );
};

const SelectWorkFlowsForm = ({
  account,
  isEditing,
}: {
  account: NewRelicAccountDetails;
  isEditing: boolean;
}) => {
  const [alertsUseWorkflows, setAlertsUseWorkflows] = useState<boolean>(
    account?.alertsUseWorkflows
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="text-sm font-semibold text-subdued">
        Select Work Flows
      </div>
      <Checkbox
        checked={alertsUseWorkflows}
        disabled={!isEditing}
        id="alerts-use-workflows"
        label="Mission Alerting Services"
        onClick={() => setAlertsUseWorkflows((prev) => !prev)}
        description={
          <>
            This option will enable ticket creation for New Relic alerts. Note
            that Domain tickets will be created regardless of this setting.
            <br />
            NOTE: If this behavior needs to be changed, create a Platform
            ticket.
          </>
        }
      />
    </div>
  );
};

const SelectResourcesForm = ({
  isAccountSetup,
  isEditing,
  resourceTemplates,
  resourceType,
  resources,
}: {
  isAccountSetup: boolean;
  isEditing: boolean;
  resourceTemplates:
    | (
        | NewRelicDashboardTemplate
        | NewRelicConditionTemplate
        | NewRelicPolicyTemplate
      )[];
  resourceType: "Dashboards" | "Conditions" | "Policies";
  resources: (NewRelicDashboard | NewRelicCondition | NewRelicPolicy)[];
}) => {
  const [selectedResources, setSelectedResources] = useState<string[]>(
    resources.map((resource) => resource.template.id)
  );

  useEffect(() => {
    if (isEditing && !isAccountSetup) {
      const defaultTemplateIds = resourceTemplates
        .filter((template) => template.default)
        .map((template) => template.id);
      setSelectedResources(defaultTemplateIds);
    }
  }, [isEditing, isAccountSetup, resourceTemplates]);

  const handleClick = (templateId: string) => {
    setSelectedResources((prev) =>
      prev.includes(templateId)
        ? prev.filter((id) => id !== templateId)
        : [...prev, templateId]
    );
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="text-sm font-semibold text-subdued">
        Select {resourceType}
      </div>
      <div>
        {resourceTemplates.map((template) => (
          <Checkbox
            checked={selectedResources.includes(template.id)}
            disabled={!isEditing}
            key={template.id}
            label={template.name}
            id={template.id}
            onClick={() => handleClick(template.id)}
          />
        ))}
      </div>
    </div>
  );
};

NewRelicAccountDetailsPage.Crumb = function Crumb() {
  const { accountId } = useParams<{ accountId: string }>();

  const { data, loading } = useQuery(GET_NEW_RELIC_ACCOUNT_DETAILS, {
    variables: { accountId },
    fetchPolicy: "cache-first",
  });

  const accountName = data?.newRelicAccount?.name ?? "Account";

  return (
    <Suspensed loading={loading} width="200px" height="20px">
      {accountName}
    </Suspensed>
  );
};

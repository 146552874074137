import { Company } from "@m/api/v4/types";
import { Badge, Suspensed } from "@m/ui";
import { formatFullDate } from "@m/utils";

import { PageTitle } from "@atlas/components";

import { DATA_MISSING_PLACEHOLDER } from "../constants";

interface CustomerDetailsHeaderProps {
  databaseId: Company["databaseId"];
  loading: boolean;
  mcn: Company["mcn"];
  name: Company["name"];
  status: Company["status"];
  timestampCreated: Company["timestampCreated"];
}

export const CustomerDetailsHeader = ({
  databaseId,
  loading,
  mcn,
  name,
  status,
  timestampCreated,
}: CustomerDetailsHeaderProps) => {
  const isActive = status === "Active";
  const displayName = name || DATA_MISSING_PLACEHOLDER;
  const displayMcn = mcn || DATA_MISSING_PLACEHOLDER;
  const dateAcquired = timestampCreated
    ? formatFullDate(timestampCreated)
    : DATA_MISSING_PLACEHOLDER;

  return (
    <PageTitle
      title={
        <Suspensed loading={loading} width="300px">
          <div className="flex items-center gap-2">
            {displayName}
            {!loading && (
              <Badge
                status={isActive ? "active" : "default"}
                label={isActive ? "Active" : "Inactive"}
              />
            )}
          </div>
        </Suspensed>
      }
      description={
        <div className="flex gap-3">
          <Suspensed loading={loading} width="95px" height="20px">
            <div aria-label="MCN">{displayMcn}</div>
          </Suspensed>

          <Suspensed loading={loading} width="180px" height="20px">
            <div className="flex gap-1">
              <div id="database-id">Database ID </div>
              <div className="text-default" aria-labelledby="database-id">
                {databaseId}
              </div>
            </div>
          </Suspensed>

          <Suspensed loading={loading} width="180px" height="20px">
            <div className="flex gap-1">
              <div id="date-acquired">Customer Since </div>
              <div aria-labelledby="date-acquired" className="text-default">
                {dateAcquired}
              </div>
            </div>
          </Suspensed>
        </div>
      }
    />
  );
};

import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";

export const GET_COMPANY_ACCESS_HUB = gql(/* GraphQL */ `
  query GetCompanyAccessHub($companySlug: String) {
    company(slug: $companySlug) {
      id
      accessHub {
        appId
        label
        embedUrl
        lastUpdated
        status
        awsAccountId
      }
    }
  }
`);

export const useCompanyAccessHub = (companySlug: string) => {
  const { data, ...result } = useQuery(GET_COMPANY_ACCESS_HUB, {
    variables: { companySlug },
  });

  const linkedAccessHub = data?.company?.accessHub;

  return { data: { linkedAccessHub }, ...result };
};

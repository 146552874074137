import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import { GetNewRelicTemplatesQuery } from "@m/api/v4/types";
import { toast } from "@m/ui";

export const GET_NEW_RELIC_TEMPLATES = gql(/* GraphQL */ `
  query GetNewRelicTemplates {
    newRelicDashboardTemplates {
      edges {
        node {
          id
          name
          default
        }
      }
    }
    newRelicConditionTemplates {
      edges {
        node {
          id
          name
          default
        }
      }
    }
    newRelicPolicyTemplates {
      edges {
        node {
          id
          name
          default
        }
      }
    }
  }
`);

export const useNewRelicTemplates = () => {
  const { data, ...result } = useQuery(GET_NEW_RELIC_TEMPLATES, {
    onError: () => toast.error("Unable to fetch New Relic templates."),
  });

  const dashboardTemplates =
    data?.newRelicDashboardTemplates?.edges?.map((edge) => edge.node) ?? [];

  const conditionTemplates =
    data?.newRelicConditionTemplates?.edges?.map((edge) => edge.node) ?? [];

  const policyTemplates =
    data?.newRelicPolicyTemplates?.edges?.map((edge) => edge.node) ?? [];

  return {
    data: { dashboardTemplates, conditionTemplates, policyTemplates },
    ...result,
  };
};

export type NewRelicDashboardTemplate =
  GetNewRelicTemplatesQuery["newRelicDashboardTemplates"]["edges"][number]["node"];

export type NewRelicConditionTemplate =
  GetNewRelicTemplatesQuery["newRelicConditionTemplates"]["edges"][number]["node"];

export type NewRelicPolicyTemplate =
  GetNewRelicTemplatesQuery["newRelicPolicyTemplates"]["edges"][number]["node"];

import { ReactNode } from "react";

/**
 * A reusable wrapper component that intercepts click events and calls stopPropagation().
 * Where used, this prevents the parent component's onClick from being triggered when
 * the child component inside the wrapper is clicked.
 */
export const StopPropagation = ({ children }: { children: ReactNode }) => {
  return <div onClick={(e) => e.stopPropagation()}>{children}</div>;
};

import { generateMockId } from "@m/api/testing";
import { CatalogNode } from "@m/api/v4/types";

import { EXAMPLE_COMPANY_CATALOG } from "@atlas/examples/";

import { CustomerListItem } from "../api";

class ExampleCustomer implements CustomerListItem {
  id: string;
  databaseId: number;
  entitlements: Array<string>;
  hasActiveMarketplaceRegistration: boolean;
  mcn: string;
  name: string;
  status: string;
  types: Array<string>;
  slug: string;
  catalog: CatalogNode;
  constructor({
    entitlements = ["Cloud Foundation"],
    hasActiveMarketplaceRegistration = false,
    mcn = `MCN-${generateMockId(7)}`,
    name = "Example Customer",
    status = "Active",
    types = ["Customer", "Cloud Foundation", "Platform Fee"],
    catalog = EXAMPLE_COMPANY_CATALOG,
    slug = "example-customer",
  } = {}) {
    this.id = generateMockId();
    this.databaseId = Number(generateMockId());
    this.entitlements = entitlements;
    this.hasActiveMarketplaceRegistration = hasActiveMarketplaceRegistration;
    this.mcn = mcn;
    this.name = name;
    this.status = status;
    this.types = types;
    this.slug = slug;
    this.catalog = catalog;
  }
}

export const EXAMPLE_CUSTOMERS = [
  new ExampleCustomer({
    entitlements: ["Cloud Foundation", "EDP", "Cloud Care", "PLBS"],
    hasActiveMarketplaceRegistration: true,
    mcn: "MCN-2764014",
    name: "Text Mechanica & Associates",
    status: "Active",
    types: [
      "Cloud Foundation",
      "EDP",
      "Platform Fee",
      "Cloud Care",
      "Mission Business Support",
      "PLBS",
    ],
  }),
  new ExampleCustomer({
    entitlements: [
      "Cloud Foundation",
      "EDP",
      "Cloud Care",
      "PLBS",
      "Cloud Elevate",
      "Cloud Crew",
    ],
    mcn: "MCN-2764012",
    name: "Bowent Entertainment LLC",
    status: "Active",
    types: [
      "Cloud Foundation",
      "EDP",
      "Platform Fee",
      "Cloud Care",
      "Mission Business Support",
      "PLBS",
      "Cloud Elevate",
      "Cloud Crew",
    ],
  }),
  new ExampleCustomer({
    entitlements: [
      "Cloud Foundation",
      "EDP",
      "Cloud Care",
      "PLBS",
      "Cloud Elevate",
      "Cloud Crew",
      "Cloud One",
      "IDP",
    ],
    mcn: "MCN-2762134",
    name: "Kuat Drive Yards",
    status: "Active",
    hasActiveMarketplaceRegistration: true,
    types: [
      "Cloud Foundation",
      "EDP",
      "Platform Fee",
      "Cloud Care",
      "Mission Business Support",
      "PLBS",
      "Cloud Elevate",
      "Cloud Crew",
      "Cloud One",
      "IDP",
    ],
  }),
  new ExampleCustomer({
    entitlements: ["MDR", "Cloud Elevate", "Cloud Crew"],
    mcn: "MCN-2763123",
    name: "Seinar Fleet Systems",
    status: "Inactive",
    types: ["MDR", "Cloud Elevate", "Cloud Crew"],
  }),
];

let i = 1;
export const EXAMPLE_CUSTOMERS_PAGINATED = Array.from({ length: 30 }, () => {
  const customer = new ExampleCustomer({
    name: `Example Customer ${i}`,
    slug: `example-customer-${i}`,
  });
  i++;
  return customer;
});

import { Outlet } from "react-router-dom";

import { AtlasLayout } from "@atlas/layouts";
import { AuthenticatedProviders } from "@atlas/providers";

import { authenticatedAtlasRoutes } from "./authenticatedAtlasRoutes";

export const atlasRoutes = [
  {
    path: "*",
    element: (
      <AuthenticatedProviders>
        <AtlasLayout>
          <Outlet />
        </AtlasLayout>
      </AuthenticatedProviders>
    ),
    children: authenticatedAtlasRoutes,
  },
];

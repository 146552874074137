import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import {
  AwsAccountFilter,
  AwsAccountSortEnum,
  AwsAccountsQuery,
} from "@m/api/v4/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

export const GET_AWS_ACCOUNTS_QUERY = gql(/* GraphQL */ `
  query AwsAccounts(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [AwsAccountSortEnum]
    $disabled: Boolean
    $where: AwsAccountFilter
  ) {
    awsAccounts(
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
      disabled: $disabled
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          accountId
          # awsAccountOnboardingStatus  // todo: add this to private api
          company {
            connectwisePk
            identifier
            name
            slug
          }
          disabled
          email
          externalId
          isSyncing
          lastCompletedSynced
          oktaApp {
            embedUrl
          }
          name
          added
          payerAccountId: rootAwsAccountId
          payerType
          signinUrl
          supportPlan
          supportsCrossAccountAuth
          syncDisableReason
          syncsEnabled
        }
      }
    }
  }
`);

interface AwsAccountsParams {
  search: string;
  sort: string;
  status: string;
}

export type AwsAccount =
  AwsAccountsQuery["awsAccounts"]["edges"][number]["node"];

export const useAwsAccounts = (query: AwsAccountsParams) => {
  const where: AwsAccountFilter = {};

  if (query.search) {
    where.or = [
      { company: { mcnIlike: `%${query.search}%` } },
      { company: { nameIlike: `%${query.search}%` } },
      { emailIlike: `%${query.search}%` },
      { idLike: `%${query.search}%` },
      { nameIlike: `%${query.search}%` },
    ];
  }

  if (query.status) {
    if (query.status === "active") where.disabled = false;
    if (query.status === "disabled") where.disabled = true;
  }

  const { data, fetchMore, ...result } = useQuery(GET_AWS_ACCOUNTS_QUERY, {
    variables: {
      ...INITIAL_PAGINATION,
      disabled: true,
      sort: query.sort as AwsAccountSortEnum,
      where,
    },
    onError: () => toast.error("Unable to load AWS accounts"),
    notifyOnNetworkStatusChange: true,
  });

  const { edges = [], pageInfo = {}, totalCount = 0 } = data?.awsAccounts ?? {};

  const awsAccounts = edges.map((edge) => edge.node) ?? [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return { data: { awsAccounts }, pagination, ...result };
};

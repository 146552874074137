import { generateMockId } from "@m/api/testing";
import {
  CatalogItem,
  CatalogItemStatus,
  CatalogItemType,
  CatalogNode,
  ProductSubtype,
} from "@m/api/v4/types";

export class ExampleCatalogItem implements CatalogItem {
  id: string;
  name: string;
  displayName: string;
  description?: string;
  sku: string;
  type?: CatalogItemType;
  status?: CatalogItemStatus;
  version?: string;
  createdTime: string;
  updatedTime: any;
  invitationsEnabled: boolean;
  isCloudScore: boolean;
  code?: string;
  constructor({
    id = generateMockId(),
    name = "Example Catalog Item",
    displayName = "Example Catalog Item",
    description,
    sku = "EXAMPLE-SKU",
    type = CatalogItemType.Product,
    status = CatalogItemStatus.Active,
    version = "1.0.0",
    createdTime = new Date().toISOString(),
    updatedTime = new Date().toISOString(),
    invitationsEnabled = true,
    isCloudScore = false,
    code,
  }: Partial<CatalogItem>) {
    this.id = id;
    this.name = name;
    this.displayName = displayName;
    this.description = description;
    this.sku = sku;
    this.type = type;
    this.status = status;
    this.version = version;
    this.createdTime = createdTime;
    this.updatedTime = updatedTime;
    this.invitationsEnabled = invitationsEnabled;
    this.isCloudScore = isCloudScore;
    this.code = code;
  }
}

export const EXAMPLE_COMPANY_CATALOG: CatalogNode = {
  addons: {
    edges: [
      {
        node: {
          id: generateMockId(),
          displayName: "Addon",
          code: null,
          createdTime: new Date().toISOString(),
          updatedTime: new Date().toISOString(),
          invitationsEnabled: true,
          isCloudScore: false,
          name: "Example Addon",
          sku: "EXAMPLE-ADDON-SKU",
          type: CatalogItemType.Product,
          subtype: ProductSubtype.Addon,
          status: CatalogItemStatus.Active,
          version: "1.0.0",
        },
        cursor: "0",
      },
    ],
    pageInfo: { hasNextPage: false, hasPreviousPage: false },
    totalCount: 1,
  },
  bundles: {
    edges: [
      {
        node: {
          id: generateMockId(),
          displayName: "Bundle",
          code: null,
          createdTime: new Date().toISOString(),
          updatedTime: new Date().toISOString(),
          invitationsEnabled: true,
          isCloudScore: false,
          name: "Example Bundle",
          sku: "EXAMPLE-BUNDLE-SKU",
          type: CatalogItemType.Bundle,
          status: CatalogItemStatus.Active,
          version: "1.0.0",
        },
        cursor: "0",
      },
    ],
    pageInfo: { hasNextPage: false, hasPreviousPage: false },
    totalCount: 1,
  },
  discounts: {
    edges: [
      {
        node: {
          id: generateMockId(),
          displayName: "Discount",
          code: null,
          createdTime: new Date().toISOString(),
          updatedTime: new Date().toISOString(),
          invitationsEnabled: true,
          isCloudScore: false,
          name: "Example Discount",
          sku: "EXAMPLE-DISCOUNT-SKU",
          type: CatalogItemType.Product,
          subtype: ProductSubtype.Discount,
          status: CatalogItemStatus.Active,
          version: "1.0.0",
        },
        cursor: "0",
      },
    ],
    pageInfo: { hasNextPage: false, hasPreviousPage: false },
    totalCount: 1,
  },
  features: {
    edges: [
      {
        node: {
          id: generateMockId(),
          displayName: "Feature",
          code: null,
          createdTime: new Date().toISOString(),
          updatedTime: new Date().toISOString(),
          invitationsEnabled: true,
          isCloudScore: false,
          name: "Example Feature",
          sku: "EXAMPLE-FEATURE-SKU",
          type: CatalogItemType.Product,
          subtype: ProductSubtype.Feature,
          status: CatalogItemStatus.Active,
          version: "1.0.0",
        },
        cursor: "0",
      },
    ],
    pageInfo: { hasNextPage: false, hasPreviousPage: false },
    totalCount: 2,
  },
  products: {
    edges: [
      {
        node: {
          id: generateMockId(),
          displayName: "Product",
          code: null,
          createdTime: new Date().toISOString(),
          updatedTime: new Date().toISOString(),
          invitationsEnabled: true,
          isCloudScore: false,
          name: "Example Product",
          sku: "EXAMPLE-PRODUCT-SKU",
          type: CatalogItemType.Product,
          status: CatalogItemStatus.Active,
          version: "1.0.0",
        },
        cursor: "0",
      },
      {
        node: {
          id: generateMockId(),
          displayName: "Marketplace Product",
          code: generateMockId(),
          createdTime: new Date().toISOString(),
          updatedTime: new Date().toISOString(),
          invitationsEnabled: true,
          isCloudScore: false,
          name: "Example Marketplace Product",
          sku: "EXAMPLE-MARKETPLACE-PRODUCT-SKU",
          type: CatalogItemType.Product,
          status: CatalogItemStatus.Active,
          version: "1.0.0",
        },
        cursor: "0",
      },
    ],
    pageInfo: { hasNextPage: false, hasPreviousPage: false },
    totalCount: 1,
  },
};

import { ChangeEvent, useCallback } from "react";

import { IndeterminateCheckbox } from "../components";
import { TABLE_TITLES } from "../constants";

interface TableInstance {
  getToggleAllRowsSelectedProps: (props: any) => any;
  toggleAllRowsSelected: (checked: boolean) => void;
}

interface UseSelectAllCheckboxProps {
  isAllRowsSelected: boolean;
  selectedRowIds: string[] | number[];
  toggleAllRowsSelected: (checked: boolean) => void;
  toggleAllRowsSelectedTitle?: string;
}

interface UseSelectAllCheckboxReturn {
  renderSelectAllCheckbox: (table: TableInstance) => JSX.Element;
}

export const useSelectAllCheckbox = ({
  isAllRowsSelected,
  selectedRowIds,
  toggleAllRowsSelected,
  toggleAllRowsSelectedTitle = TABLE_TITLES.TOGGLE_ALL_ROWS_SELECTED,
}: UseSelectAllCheckboxProps): UseSelectAllCheckboxReturn => {
  const renderSelectAllCheckbox = useCallback(
    (table: TableInstance) => (
      <IndeterminateCheckbox
        {...table.getToggleAllRowsSelectedProps({
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const { checked } = e.target;
            table.toggleAllRowsSelected(checked);
            toggleAllRowsSelected(checked);
          },
          checked: isAllRowsSelected,
          indeterminate: Boolean(selectedRowIds?.length),
          title: toggleAllRowsSelectedTitle,
        })}
      />
    ),
    [
      isAllRowsSelected,
      selectedRowIds,
      toggleAllRowsSelected,
      toggleAllRowsSelectedTitle,
    ]
  );

  return {
    renderSelectAllCheckbox,
  };
};

import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { FC } from "react";

export interface TogglePropsStrict {
  /** Toggle is checked / on / active */
  checked: boolean;

  /** Additional classes */
  className?: string;

  /** Disabled Toggle, unclickable */
  disabled?: boolean;

  /** Toggle is checked / on / active */
  onChange?: (checked: boolean) => void;
}

interface ToggleProps extends TogglePropsStrict {
  /** Unstrict Props */
  [propName: string]: any;
}

export const Toggle: FC<ToggleProps> = ({
  checked,
  className,
  disabled,
  onChange,
  ...props
}) => {
  const classes = clsx(
    "relative inline-flex p-0.5 w-[44px] rounded-full",
    {
      "bg-emerald-400 justify-end": checked,
      "bg-form-border justify-start": !checked,

      // Disabled styles
      "opacity-60 cursor-default pointer-events-none": disabled,
    },
    className
  );
  return (
    <Switch
      className={classes}
      checked={checked}
      onChange={onChange}
      {...props}
    >
      <span className="inline-block h-2 w-2 rounded-full bg-white" />
    </Switch>
  );
};

export const ToggleLabel = Switch.Label;
export const ToggleContainer = Switch.Group;

import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

import { GET_CUSTOMER_NEW_RELIC_ACCOUNTS } from "./useCustomerNewRelicAccounts";

export const CREATE_NEW_RELIC_ACCOUNT = gql(/* GraphQL */ `
  mutation CreateNewRelicAccount($input: CreateNewRelicAccountInput!) {
    createNewRelicAccount(input: $input) {
      ok
      message
    }
  }
`);

export const useCreateNewRelicAccount = (companySlug: string) => {
  const [mutate, result] = useMutation(CREATE_NEW_RELIC_ACCOUNT, {
    refetchQueries: [
      {
        query: GET_CUSTOMER_NEW_RELIC_ACCOUNTS,
        variables: { slug: companySlug },
      },
    ],
    onError: () => toast.error("Unable to create New Relic account."),
    onCompleted: (data) => {
      if (data.createNewRelicAccount.ok) {
        toast.success(data.createNewRelicAccount.message);
      } else {
        toast.error(data.createNewRelicAccount.message);
      }
    },
  });

  const createNewRelicAccount = (name: string) =>
    mutate({ variables: { input: { name, companySlug } } });

  return [createNewRelicAccount, result] as const;
};

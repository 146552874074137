import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { AddAwsAccountMutation } from "@m/api/v4/types";
import { toast } from "@m/ui";

import { PayerType } from "../types";

export const ADD_AWS_ACCOUNT = gql(/* GraphQL */ `
  mutation AddAwsAccount($input: UpdateAwsAccountInput!) {
    updateAwsAccount(input: $input) {
      ok
      message
      awsAccount {
        accountId
        name
      }
    }
  }
`);

type AddAwsAccountInput = {
  id: string;
  name: string;
  isPayer: boolean;
  companyId: string;
};

export const useAddAwsAccount = () => {
  const handleError = () => toast.error("Unable to add AWS account");

  const [mutate, result] = useMutation(ADD_AWS_ACCOUNT, {
    onCompleted: (data: AddAwsAccountMutation) => {
      if (!data.updateAwsAccount.ok) return handleError();
      const { accountId, name } = data.updateAwsAccount.awsAccount;
      toast.success(`AWS account added: ${name || accountId}`);
    },
    onError: handleError,
  });

  const addAwsAccount = ({
    id,
    name,
    isPayer,
    companyId,
  }: AddAwsAccountInput) => {
    const payerType = isPayer ? PayerType.Dedicated : PayerType.NotSet;
    return mutate({
      variables: { input: { id, name, payerType, companyId, isCreate: true } },
    });
  };

  return [addAwsAccount, result] as const;
};

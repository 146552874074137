import { Navigate, Outlet } from "react-router-dom";

import { AccessRequired } from "@m/api/components";

import { ATLAS_FEATURE_FLAGS, PATHS } from "@atlas/constants";
import {
  CustomerDetailsPage,
  CustomerProfessionalServicesPage,
  CustomerSubscriptionDetailsPage,
  CustomerSubscriptionsPage,
  CustomersPage,
} from "@atlas/features/Customers";
import { CustomerDetailsLayout } from "@atlas/features/Customers/layouts";

import { newRelicRoutes } from "./newRelicRoutes";

export const customersRoutes = [
  {
    element: <CustomersPage />,
    index: true,
  },
  {
    path: ":companySlug",
    element: (
      <CustomerDetailsLayout>
        <Outlet />
      </CustomerDetailsLayout>
    ),
    handle: {
      crumb: () => <CustomerDetailsLayout.Crumb />,
      to: PATHS.CUSTOMER_DETAILS,
    },
    children: [
      {
        index: true,
        element: <Navigate to="overview" replace />,
      },
      {
        path: "overview",
        element: <CustomerDetailsPage />,
      },
      {
        path: "subscriptions",
        handle: {
          crumb: () => <span>Subscriptions</span>,
          to: PATHS.CUSTOMER_SUBSCRIPTIONS,
        },
        children: [
          {
            index: true,
            element: <CustomerSubscriptionsPage />,
          },
          {
            path: ":marketplaceProductCode",
            element: <CustomerSubscriptionDetailsPage />,
            handle: {
              crumb: () => <CustomerSubscriptionDetailsPage.Crumb />,
              to: PATHS.CUSTOMER_SUBSCRIPTION_DETAILS,
            },
          },
        ],
      },
      {
        path: "professional-services",
        handle: {
          crumb: () => <span>Professional Services</span>,
          to: PATHS.CUSTOMER_PROFESSIONAL_SERVICES,
        },
        element: <CustomerProfessionalServicesPage />,
      },
      {
        path: "new-relic",
        element: (
          <AccessRequired feature={ATLAS_FEATURE_FLAGS.RELEASE_PORTAL_REDESIGN}>
            <Outlet />
          </AccessRequired>
        ),
        handle: {
          crumb: () => <span>New Relic</span>,
          to: PATHS.CUSTOMER_NEW_RELIC_ACCOUNTS,
        },
        children: newRelicRoutes,
      },
    ],
  },
];

import { CatalogItemCategory } from "@m/api/public/types";
import { generateMockId } from "@m/api/testing";
import { CatalogItem, CatalogItemStatus } from "@m/api/v4/types";

import { RegistrationCatalogItem } from "../api";

import { EXAMPLE_DIMENSIONS } from "./dimension.examples";

export class ExampleCatalogItem
  implements Pick<CatalogItem, "id" | "name" | "sku">
{
  id: string;
  name: string;
  sku: string;
  constructor({
    id = generateMockId(),
    name = "Example Catalog Item",
    sku = "EXAMPLE-SKU",
  }: Partial<CatalogItem>) {
    this.id = id;
    this.name = name;
    this.sku = sku;
  }
}

export const EXAMPLE_CATALOG = [
  new ExampleCatalogItem({ name: "Example Addon", sku: "EXAMPLE-ADDON-SKU" }),
  new ExampleCatalogItem({ name: "Example Bundle", sku: "EXAMPLE-BUNDLE-SKU" }),
  new ExampleCatalogItem({
    name: "Example Discount",
    sku: "EXAMPLE-DISCOUNT-SKU",
  }),
  new ExampleCatalogItem({
    name: "Example Product",
    sku: "EXAMPLE-PRODUCT-SKU",
  }),
  new ExampleCatalogItem({
    name: "Example Feature",
    sku: "EXAMPLE-FEATURE-SKU",
  }),
  new ExampleCatalogItem({
    name: "Example Marketplace Product",
    sku: "EXAMPLE-MARKETPLACE-PRODUCT-SKU",
  }),
];

export const EXAMPLE_CATALOG_ITEM = {
  id: generateMockId(),
  dimensions: EXAMPLE_DIMENSIONS,
  description: "This is a big product!",
  displayName: "Big Product",
  sku: "MC-SAAS-BIG-PRODUCT-2024-07",
  category: CatalogItemCategory.SaasContract,
  awsProductId: generateMockId(),
  productCode: generateMockId(),
  status: CatalogItemStatus.Active,
} satisfies RegistrationCatalogItem;

import { useLazyQuery } from "@apollo/client";
import { useState } from "react";

import { gql } from "@m/api/v4";
import { FindCompanyAccessHubQuery } from "@m/api/v4/types";
import { toast } from "@m/ui";

export const FIND_COMPANY_ACCESS_HUB = gql(/* GraphQL */ `
  query FindCompanyAccessHub($oktaAppId: String!) {
    companyAccessHub(oktaAppId: $oktaAppId) {
      appId
      label
      embedUrl
      lastUpdated
      status
      company {
        id
        name
        slug
      }
    }
  }
`);

type OktaApp = FindCompanyAccessHubQuery["companyAccessHub"];

export const useFindCompanyAccessHub = () => {
  const [oktaApp, setOktaApp] = useState<OktaApp | undefined>(undefined);

  const [fetch, result] = useLazyQuery(FIND_COMPANY_ACCESS_HUB, {
    onCompleted: (data) => setOktaApp(data?.companyAccessHub ?? null),
    onError: () => toast.error("Error fetching company access hub"),
  });

  const reset = () => setOktaApp(undefined);

  const findCompanyAccessHub = (oktaAppId: string) => {
    reset();
    fetch({ variables: { oktaAppId } });
  };

  return [
    findCompanyAccessHub,
    {
      ...result,
      data: { companyAccessHub: oktaApp },
      reset,
    },
  ] as const;
};

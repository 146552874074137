/**
 * Paths constant used to keep track of all of the path segments used throughout Atlas.
 *
 * Relative paths are prefixed with an underscore (_).
 */
export const PATHS = {
  // Root
  ROOT: "/",

  // AWS Accounts
  AWS_ACCOUNTS: "/accounts",

  // Customers
  CUSTOMERS: "/customers",
  CUSTOMER_DETAILS: "/customers/:companySlug/overview",
  CUSTOMER_SUBSCRIPTIONS: "/customers/:companySlug/subscriptions",
  CUSTOMER_SUBSCRIPTION_DETAILS:
    "/customers/:companySlug/subscriptions/:marketplaceProductCode",
  CUSTOMER_PROFESSIONAL_SERVICES:
    "/customers/:companySlug/professional-services",
  CUSTOMER_NEW_RELIC_ACCOUNTS: "/customers/:companySlug/new-relic",
  CUSTOMER_NEW_RELIC_ACCOUNT_DETAILS:
    "/customers/:companySlug/new-relic/:accountId",
  CUSTOMER_NEW_RELIC_ACCOUNT_DOMAIN_MONITORS:
    "/customers/:companySlug/new-relic/:accountId/domain-monitors",

  // Settings
  SETTINGS: "/settings",
  SETTINGS_ACCESS_TOKENS: "/settings/access-tokens",
  SETTINGS_ALERT_CONDITIONS: "/settings/alert-conditions",
  SETTINGS_DASHBOARDS: "/settings/dashboards",
};

import { useQuery } from "@m/api";
import { gql } from "@m/api/v4";
import { CustomerSubscriptionsQuery } from "@m/api/v4/types";

export const GET_CUSTOMER_SUBSCRIPTIONS = gql(/* GraphQL */ `
  query CustomerSubscriptions($companySlug: String!) {
    marketplaceRegistrations(companySlug: $companySlug) {
      edges {
        node {
          id
          lastSuccessfulMeteredUsageTimestamp
          marketplaceProductCode
          expirationTime
          registrationStatus
          catalogItem {
            id
            status
            displayName
          }
        }
      }
    }
  }
`);

export type CustomerSubscription =
  CustomerSubscriptionsQuery["marketplaceRegistrations"]["edges"][number]["node"];
export type SubscriptionCatalogItem = CustomerSubscription["catalogItem"];

export const useCustomerSubscriptions = (companySlug: string) => {
  const { data, ...result } = useQuery(GET_CUSTOMER_SUBSCRIPTIONS, {
    variables: { companySlug },
  });

  const subscriptions =
    data?.marketplaceRegistrations?.edges.map((edge) => edge.node) ?? [];

  return {
    data: { subscriptions },
    ...result,
  };
};

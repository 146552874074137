import { dt } from "@m/utils";

type GetMeteredChargeDateFormatParams = {
  year?: number | null;
  month?: number | null;
  day?: number | null;
  hour?: number | null;
  isoDate?: string | null;
  formats?: { current: string; past: string };
};

// determine date format based on age of charge and current year
export const formatMeteredChargeDate = ({
  hour,
  day,
  month,
  year,
  isoDate,
  formats = { current: "MMMM d", past: "MMMM d, yyyy" },
}: GetMeteredChargeDateFormatParams): string => {
  const dateFromSegments = getDtObjectFromSegments({ year, month, day, hour });
  const dateFromISO = dt.fromISO(isoDate ?? "");
  const date = dateFromISO.isValid ? dateFromISO : dateFromSegments;

  if (!date?.isValid) return "—";

  const { current, past } = formats;

  const targetYear = date.year;
  const currentYear = dt.now().year;
  return targetYear < currentYear
    ? date.toFormat(past)
    : date.toFormat(current);
};

const getDtObjectFromSegments = ({
  hour,
  day,
  month,
  year,
}: {
  hour?: number | null;
  day?: number | null;
  month?: number | null;
  year?: number | null;
}): dt | null => {
  // fromObject defaults to current time if there are no valid arguments
  // so we need to handle the case ourselves
  if (!hour && !day && !month && !year) return null;
  return dt.fromObject({
    hour: hour ?? undefined,
    day: day ?? undefined,
    month: month ?? undefined,
    year: year ?? undefined,
  });
};

import { AccessRequired } from "@m/api/components";
import { Button, useModalState } from "@m/ui";

import { PageTitle } from "@atlas/components/PageTitle";
import { ATLAS_MUTATIONS } from "@atlas/constants";

import { AddAwsAccountModal, AwsAccountsTable } from "../components";

export const AwsAccountsPage = () => {
  const addAccountModal = useModalState();

  return (
    <main className="flex flex-col gap-3 p-3">
      <PageTitle
        title="AWS Accounts"
        actions={
          <AccessRequired mutation={ATLAS_MUTATIONS.UPDATE_AWS_ACCOUNT}>
            <Button kind="primary" size="small" onClick={addAccountModal.open}>
              Add Account
            </Button>
          </AccessRequired>
        }
      />
      <AwsAccountsTable />
      {addAccountModal.isOpen && (
        <AddAwsAccountModal onClose={addAccountModal.close} />
      )}
    </main>
  );
};

import { PATHS } from "@atlas/constants";
import {
  CustomerNewRelicAccountsPage,
  NewRelicAccountDetailsPage,
  NewRelicAccountDomainMonitorsPage,
} from "@atlas/features/NewRelic/pages";

export const newRelicRoutes = [
  {
    index: true,
    handle: {
      crumb: () => <span>Accounts</span>,
      to: PATHS.CUSTOMER_NEW_RELIC_ACCOUNTS,
    },
    element: <CustomerNewRelicAccountsPage />,
  },
  {
    path: ":accountId",
    handle: {
      crumb: () => <NewRelicAccountDetailsPage.Crumb />,
      to: PATHS.CUSTOMER_NEW_RELIC_ACCOUNT_DETAILS,
    },
    children: [
      {
        index: true,
        element: <NewRelicAccountDetailsPage />,
      },
      {
        path: "domain-monitors",
        handle: {
          crumb: () => <span>Domain Monitors</span>,
          to: PATHS.CUSTOMER_NEW_RELIC_ACCOUNT_DOMAIN_MONITORS,
        },
        element: <NewRelicAccountDomainMonitorsPage />,
      },
    ],
  },
];

import { InputHTMLAttributes, forwardRef, useEffect, useRef } from "react";

interface IndeterminateCheckboxProps
  extends InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
}

export const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef = (ref ||
    defaultRef) as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate ?? false;
  }, [resolvedRef, indeterminate]);

  return (
    <div>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </div>
  );
});

/* forwardRef components do not automatically get a displayName, which is useful in debugging */
IndeterminateCheckbox.displayName = "IndeterminantCheckbox";

import { generateMockId } from "@m/api/testing";

import { CustomerDetails } from "../api";

export class ExampleCustomer implements CustomerDetails {
  id: string;
  salesforceCustomerAccount: {
    website: string;
    phoneNumber: string;
    companySize: number;
    industry: string;
    awsRep: string;
    awsSegment: string;
    awsPhases: string;
    awsDistrict: string;
  };
  name: string;
  slug: string;
  databaseId: number;
  connectwisePk: number;
  identifier: string;
  mcn: string;
  status: string;
  timestampCreated: string;
  types: string[];
  companyEntitlements: {
    bundles: {
      edges: {
        node: {
          sku: string;
          name: string;
        };
      }[];
    };
    products: {
      edges: {
        node: {
          sku: string;
          name: string;
        };
      }[];
    };
    addons: {
      edges: {
        node: {
          sku: string;
          name: string;
        };
      }[];
    };
    discounts: {
      edges: {
        node: {
          sku: string;
          name: string;
        };
      }[];
    };
  };

  constructor({
    id = generateMockId(),
    salesforceCustomerAccount = {
      website: "https://example.com",
      phoneNumber: "123-456-7890",
      companySize: 100,
      industry: "Tech",
      awsRep: "Example AWS Rep",
      awsSegment: "Example AWS Segment",
      awsPhases: "Example AWS Phases",
      awsDistrict: "Example AWS District",
    },
    name = "Example Customer",
    slug = "example-customer",
    mcn = `MCN-${generateMockId()}`,
    status = "Active",
    timestampCreated = "2023-01-01",
    types = ["Customer"],
    companyEntitlements = {
      bundles: {
        edges: [
          {
            node: {
              sku: "MS-B-C1-2021-09",
              name: "Cloud One",
            },
          },
        ],
      },
      products: {
        edges: [
          {
            node: {
              sku: "PS-P-PROSERV-2018-01",
              name: "Professional Services",
            },
          },
        ],
      },
      addons: {
        edges: [
          {
            node: {
              sku: "MS-A-PLATFORM-2023-06",
              name: "Platform Fee",
            },
          },
        ],
      },
      discounts: {
        edges: [
          {
            node: {
              sku: "RS-D-IDP-2020-01",
              name: "Instance Discount Program",
            },
          },
        ],
      },
    },
  } = {}) {
    this.id = id;
    this.salesforceCustomerAccount = salesforceCustomerAccount;
    this.name = name;
    this.slug = slug;
    this.databaseId = Number(id);
    this.connectwisePk = Number(id);
    this.identifier = `P-${id}`;
    this.mcn = mcn;
    this.status = status;
    this.timestampCreated = timestampCreated;
    this.types = types;
    this.companyEntitlements = companyEntitlements;
  }
}

export const EXAMPLE_CUSTOMER = new ExampleCustomer();

export const EXAMPLE_CUSTOMER_WITH_NO_ENTITLEMENTS = new ExampleCustomer({
  companyEntitlements: {
    bundles: {
      edges: [],
    },
    products: {
      edges: [],
    },
    addons: {
      edges: [],
    },
    discounts: {
      edges: [],
    },
  },
});

import { generateMockId } from "@m/api/testing";
import {
  CatalogItemCategory,
  CatalogItemStatus,
  CatalogItemType,
  MarketplaceRegistrationStatus,
  MarketplaceSubscriptionDurationType,
} from "@m/api/v4/types";
import { dt } from "@m/utils";

import { CustomerSubscription, SubscriptionCatalogItem } from "../api";

export class ExampleSubscriptionCatalogItem implements SubscriptionCatalogItem {
  id: string;
  retireDate: string;
  name: string;
  displayName: string;
  type: CatalogItemType;
  sku: string;
  productCode: string;
  subscriptionDurationType: MarketplaceSubscriptionDurationType;
  launchDate: string;
  category: CatalogItemCategory;
  description: string;
  status: CatalogItemStatus;
  version: string;

  constructor({
    id = generateMockId(),
    retireDate = dt.now().plus({ months: 11 }).toISO(),
    name = "Mission Cloud Score",
    displayName = "Mission Cloud Score",
    type = CatalogItemType.Product,
    sku = "MC-SAAS-CLOUDSCORE-2023-11",
    productCode = generateMockId(),
    subscriptionDurationType = MarketplaceSubscriptionDurationType.EntitlementExpiration,
    launchDate = dt.now().toISO(),
    category = CatalogItemCategory.Saas,
    description = "Default description",
    status = CatalogItemStatus.Active,
    version = "1.0.0",
  } = {}) {
    this.id = id;
    this.retireDate = retireDate;
    this.name = name;
    this.displayName = displayName;
    this.type = type;
    this.sku = sku;
    this.productCode = productCode;
    this.subscriptionDurationType = subscriptionDurationType;
    this.launchDate = launchDate;
    this.category = category;
    this.description = description;
    this.status = status;
    this.version = version;
  }
}

export class ExampleCustomerSubscription implements CustomerSubscription {
  id: string;
  databaseId: string;
  subscribeTime: string;
  catalogItem: SubscriptionCatalogItem;
  marketplaceProductCode: string;
  registrationStatus: MarketplaceRegistrationStatus;
  lastSuccessfulMeteredUsageTimestamp: string;
  privateOfferId: string;
  companyId: number;
  customerIdentifier: string;
  expirationTime: string;

  constructor({
    catalogItem = new ExampleSubscriptionCatalogItem(),
    companyId = 1234,
    customerIdentifier = generateMockId(),
    databaseId = generateMockId(),
    expirationTime = dt.now().plus({ months: 11 }).toISO(),
    registrationStatus = MarketplaceRegistrationStatus.Active,
    marketplaceProductCode = "1234",
    id = generateMockId(),
    lastSuccessfulMeteredUsageTimestamp = dt.now().minus({ days: 6 }).toISO(),
    privateOfferId = generateMockId(),
    subscribeTime = dt.now().minus({ months: 1 }).toISO(),
  } = {}) {
    this.id = id;
    this.databaseId = databaseId;
    this.subscribeTime = subscribeTime;
    this.catalogItem = catalogItem;
    this.marketplaceProductCode = marketplaceProductCode;
    this.registrationStatus = registrationStatus;
    this.lastSuccessfulMeteredUsageTimestamp =
      lastSuccessfulMeteredUsageTimestamp;
    this.privateOfferId = privateOfferId;
    this.companyId = companyId;
    this.customerIdentifier = customerIdentifier;
    this.expirationTime = expirationTime;
  }
}

export const EXAMPLE_CUSTOMER_SUBSCRIPTIONS = [
  new ExampleCustomerSubscription(),
  new ExampleCustomerSubscription({
    lastSuccessfulMeteredUsageTimestamp: dt.now().minus({ days: 12 }).toISO(),
    expirationTime: dt.now().plus({ days: 5 }).toISO(),
    registrationStatus: MarketplaceRegistrationStatus.Inactive,
    catalogItem: new ExampleSubscriptionCatalogItem({
      name: "Cloud Foundation",
      displayName: "Cloud Foundation",
      status: CatalogItemStatus.Inactive,
      sku: generateMockId(),
    }),
  }),
  new ExampleCustomerSubscription({
    expirationTime: dt.now().minus({ months: 2 }).toISO(),
    registrationStatus: MarketplaceRegistrationStatus.Processing,
    catalogItem: new ExampleSubscriptionCatalogItem({
      name: "Cloud Gateway",
      displayName: "Cloud Gateway",
      status: CatalogItemStatus.Deprecated,
      sku: generateMockId(),
    }),
  }),
  new ExampleCustomerSubscription({
    expirationTime: dt.now().plus({ days: 2 }).toISO(),
    registrationStatus: MarketplaceRegistrationStatus.Unsubscribed,
    catalogItem: new ExampleSubscriptionCatalogItem({
      name: "Cloud One",
      displayName: "Cloud One",
      status: CatalogItemStatus.Preview,
      sku: generateMockId(),
    }),
  }),
];

import * as React from "react";

export interface CheckboxProps extends React.ComponentPropsWithoutRef<"input"> {
  label?: React.ReactNode;
  description?: React.ReactNode;
}

const Checkbox = React.forwardRef<React.ElementRef<"input">, CheckboxProps>(
  ({ className, label, description, ...props }, ref) => (
    <div className="flex items-center gap-1">
      <input type="checkbox" ref={ref} className={className} {...props} />
      {(label || description) && (
        <label className="flex flex-col items-start" htmlFor={props.id}>
          {label && <div className="text-sm font-semibold">{label}</div>}
          {description && (
            <div className="text-xs text-subdued">{description}</div>
          )}
        </label>
      )}
    </div>
  )
);
Checkbox.displayName = "Checkbox";

export { Checkbox };

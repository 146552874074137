import { ReactNode } from "react";

import {
  MarketplaceMeteringStatus,
  MarketplaceRegistrationStatus,
} from "@m/api/v4/types";
import { Badge, Suspensed } from "@m/ui";
import { formatFullDate } from "@m/utils";

import { SubscriptionInfoBadge } from "@atlas/components";

import { SubscriptionDetails } from "../api";

interface SubscriptionDetailsItemsProps {
  loading: boolean;
  subscriptionDetails: SubscriptionDetails | null;
}

export const SubscriptionDetailsItems = ({
  loading,
  subscriptionDetails,
}: SubscriptionDetailsItemsProps) => {
  const {
    awsAccountId = "",
    privateOfferId = "",
    registrationStatus = null,
    expirationTime = null,
    subscribeTime = null,
    unsubscribeTime = null,
    payerAccountId = "",
    meteringStatus = null,
    onboardingCompleteTimestamp = null,
  } = subscriptionDetails || {};

  const isMeteringDisabled =
    meteringStatus === MarketplaceMeteringStatus.Inactive;
  const isUnsubscribed =
    registrationStatus === MarketplaceRegistrationStatus.Unsubscribed;

  return (
    <section className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <div className="font-semibold">Subscription</div>
        <Suspensed height="16px" width="46px" loading={loading}>
          <SubscriptionInfoBadge
            status={registrationStatus}
            aria-label="Subscription Status"
          />
          {isMeteringDisabled && (
            <Badge label="Metering Disabled" status="negative" size="small" />
          )}
        </Suspensed>
      </div>
      <div className="flex flex-wrap gap-2">
        <SubscriptionItem
          title="Subscriber Account ID"
          value={awsAccountId}
          loading={loading}
        />
        <SubscriptionItem
          title="Private Offer ID"
          value={privateOfferId}
          loading={loading}
        />
        <SubscriptionItem
          title="Payer Account ID"
          value={payerAccountId}
          loading={loading}
        />
        <SubscriptionItem
          title="Onboarded"
          value={
            formatFullDate(onboardingCompleteTimestamp, "MMMM d, yyyy") || null
          }
          loading={loading}
        />
        <SubscriptionItem
          title="Subscribed"
          value={formatFullDate(subscribeTime, "MMMM d, yyyy") || null}
          loading={loading}
        />
        <SubscriptionItem
          title={isUnsubscribed ? "Unsubscribed" : "Expires"}
          value={
            isUnsubscribed
              ? formatFullDate(unsubscribeTime, "MMMM d, yyyy")
              : formatFullDate(expirationTime, "MMMM d, yyyy")
          }
          loading={loading}
        />
      </div>
    </section>
  );
};

const SubscriptionItem = ({
  title,
  loading,
  value,
}: {
  title: string;
  loading: boolean;
  value: ReactNode;
}) => {
  const id = `${title.replace(/\s+/g, "-").toLowerCase()}`;
  return (
    <div className="flex grow flex-col text-xs">
      <div id={id} className="font-semibold">
        {title}
      </div>
      <div className="break-words text-subdued" aria-labelledby={id}>
        <Suspensed height="16px" width="82px" loading={loading}>
          {value || "—"}
        </Suspensed>
      </div>
    </div>
  );
};

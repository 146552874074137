import { generateMockId } from "@m/api/testing";
import { Company, OktaApp } from "@m/api/v4/types";

export class ExampleOktaApp implements Partial<OktaApp> {
  appId: string;
  label: string;
  embedUrl: string;
  lastUpdated: string;
  status: "ACTIVE" | "INACTIVE";
  company: Company | null;
  awsAccountId: string;

  constructor({
    appId = generateMockId(20, true),
    label = `example-aws-access-hub-${appId.slice(-4)}`,
    embedUrl = `https://example.okta.com/home/amazon_aws/${appId.slice(-4)}`,
    lastUpdated = new Date("2025-01-23T12:00:00Z").toISOString(),
    status = "ACTIVE",
    company = null,
    awsAccountId = "332648210842",
  } = {}) {
    this.appId = appId;
    this.label = label;
    this.embedUrl = embedUrl;
    this.lastUpdated = lastUpdated;
    this.status = status as "ACTIVE" | "INACTIVE";
    this.company = company;
    this.awsAccountId = awsAccountId;
  }
}

export const EXAMPLE_OKTA_APP = new ExampleOktaApp();

import {
  ReactNode,
  createContext,
  createRef,
  useContext,
  useMemo,
} from "react";

import { useAuth0Logout, useUserSession } from "@m/login/hooks";
import { UserSession } from "@m/types";

interface AuthProviderProps {
  children: ReactNode;
  defaultUser?: UserSession;
}

export const AuthProvider = ({
  defaultUser = null,
  children,
}: AuthProviderProps) => {
  const { user, updateUserSession } = useUserSession(defaultUser);
  const logout = useAuth0Logout();

  const isAuthenticated = !!user;

  const authContextValue = useMemo(
    () => ({ isAuthenticated, user, updateUserSession, logout }),
    [isAuthenticated, user, updateUserSession, logout]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

interface AuthContextValue {
  isAuthenticated: boolean;
  logout: () => void;
  updateUserSession: (user: UserSession) => void;
  user: UserSession | null;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = createContext<AuthContextValue>(undefined!);

export const AuthContextRef = createRef();

export const useAuth = () => useContext(AuthContext);

export const batchUpdate = async <T>(
  inputs: T[],
  mutate: (input: T) => Promise<any>
) => {
  const responses = await Promise.allSettled(
    inputs.map((input) => {
      return new Promise((resolve, reject) => {
        mutate(input)
          .then((result) => resolve({ input, result }))
          .catch((error) => reject({ input, error }));
      });
    })
  );

  return responses;
};

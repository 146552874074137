import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

export const GET_CUSTOMER_NEW_RELIC_ACCOUNTS = gql(/* GraphQL */ `
  query GetCustomerNewRelicAccounts($slug: String!) {
    company(slug: $slug) {
      id
      newrelicAccounts {
        edges {
          node {
            accountId
            id
            lastSynced
            name
            status
            syncState
          }
        }
      }
    }
  }
`);

export const useCustomerNewRelicAccounts = (companySlug: string) => {
  const { data, ...result } = useQuery(GET_CUSTOMER_NEW_RELIC_ACCOUNTS, {
    variables: {
      slug: companySlug,
    },
    onError: () => toast.error("Unable to fetch New Relic accounts."),
  });

  const newRelicAccounts =
    data?.company?.newrelicAccounts?.edges?.map((edge) => edge.node) ?? [];

  return {
    data: { newRelicAccounts },
    ...result,
  };
};

import { PropsWithChildren } from "react";

import { Breadcrumbs } from "@atlas/components";

import { SettingsSidebar } from "../components";

export const SettingsLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="min-w-screen flex h-full min-h-screen w-full flex-col p-3">
      <Breadcrumbs />
      <div className="mt-2 flex min-w-[450px] grow">
        <SettingsSidebar />
        <div className="flex grow flex-col overflow-auto">{children}</div>
      </div>
    </div>
  );
};

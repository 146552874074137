import { ReactNode } from "react";

interface PageTitleProps {
  title: ReactNode;
  actions?: ReactNode;
  description?: ReactNode;
}

export const PageTitle = ({ title, actions, description }: PageTitleProps) => (
  <div className="flex flex-col">
    <div className="flex justify-between">
      <div className="text-lg font-bold">{title}</div>
      {actions}
    </div>
    {description && (
      <div className="text-sm font-semibold text-subdued">{description}</div>
    )}
  </div>
);

import {
  AppState,
  Auth0Provider as BaseAuth0Provider,
} from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

interface Auth0ProviderProps {
  clientId: string;
  children: React.ReactNode;
}

export const Auth0Provider = ({ clientId, children }: Auth0ProviderProps) => {
  const navigate = useNavigate();

  const handleRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  const audience =
    process.env.REACT_APP_AUTH0_AUDIENCE || process.env.REACT_APP_SERVICES_API;

  return (
    <BaseAuth0Provider
      audience={audience}
      clientId={clientId}
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      onRedirectCallback={handleRedirectCallback}
      redirectUri={window.location.origin}
    >
      {children}
    </BaseAuth0Provider>
  );
};

import { CheckIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, FormEvent, useMemo, useState } from "react";

import { batchUpdate, generateUniqueId } from "@m/api";
import {
  Button,
  Buttons,
  Checkbox,
  Field,
  Fields,
  Input,
  Modal,
  Select,
  Suspensed,
  toast,
} from "@m/ui";

import {
  useAddAwsAccount,
  useCanRegisterAwsAccountId,
  useCompanies,
} from "../api";

interface AddAwsAccountModalProps {
  onClose: () => void;
}

export const AddAwsAccountModal = ({ onClose }: AddAwsAccountModalProps) => {
  const {
    data: { companies },
    loading: companiesLoading,
  } = useCompanies();

  const [validateAccountId] = useCanRegisterAwsAccountId();

  const [addAwsAccount, { loading: addAwsAccountLoading }] = useAddAwsAccount();

  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [accounts, setAccounts] = useState<AwsAccountForm[]>([
    new AwsAccountForm(),
  ]);

  // Helper functions

  const setAccountIdError = (
    index: number,
    error: string,
    isValid: boolean | null
  ) =>
    setAccounts((prev) =>
      prev.map((account, i) =>
        i === index
          ? { ...account, accountIdError: error, isAccountIdValid: isValid }
          : account
      )
    );

  const resetAccountIdErrors = () =>
    setAccounts((prev) =>
      prev.map((account) => ({
        ...account,
        accountIdError: "",
        isAccountIdValid: null,
      }))
    );

  // Event handlers

  const handleAddAccountForm = () => {
    setAccounts((prev) => [...prev, new AwsAccountForm()]);
  };

  const handleRemoveAccountForm = (index: number) => {
    setAccounts((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAccountValidityResponse = (
    index: number,
    isValid: boolean | null
  ) => {
    const error =
      isValid === false ? "Unable to register this AWS account." : "";
    setAccountIdError(index, error, isValid);
  };

  const handleChangeAccountId = (index: number, value: string) => {
    setAccountIdError(index, "", null);
    if (isNaN(Number(value))) return;

    setAccounts((prev) =>
      prev.map((account, i) =>
        i === index ? { ...account, accountId: value } : account
      )
    );

    if (value.length === 12 && selectedCompanyId)
      validateAccountId(value, selectedCompanyId).then((isValid) => {
        handleAccountValidityResponse(index, isValid);
      });
  };

  const handleChangeAccountName = (index: number, value: string) =>
    setAccounts((prev) =>
      prev.map((account, i) =>
        i === index ? { ...account, accountName: value } : account
      )
    );

  const handleChangeIsPayer = (index: number, value: boolean) =>
    setAccounts((prev) =>
      prev.map((account, i) =>
        i === index ? { ...account, isPayer: value } : account
      )
    );

  const handleChangeSelectedCompany = ({
    target: { value: companyId },
  }: ChangeEvent<HTMLSelectElement>) => {
    resetAccountIdErrors();
    if (!companyId) return;

    setSelectedCompanyId(companyId);
    accounts.forEach((account, index) => {
      if (account.accountId.length === 12) {
        validateAccountId(account.accountId, companyId).then((isValid) => {
          handleAccountValidityResponse(index, isValid);
        });
      }
    });
  };

  const handleSubmitAddAccount = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const inputs = accounts.map((account) => ({
      companyId: selectedCompanyId,
      id: account.accountId,
      name: account.accountName,
      isPayer: account.isPayer,
    }));

    const responses = await batchUpdate(inputs, addAwsAccount);

    const failed = responses.filter(
      (response) => response.status === "rejected"
    );

    if (failed.length > 0)
      toast.error("Failed to add one or more AWS accounts.");

    onClose();
  };

  const companyOptions = useMemo(
    () =>
      companies.map((company) => (
        <option key={company.id} value={company.id}>
          {company.name}
        </option>
      )),
    [companies]
  );

  const isAddAccountDisabled =
    !selectedCompanyId || accounts.some((account) => !account.isAccountIdValid);

  return (
    <Modal
      header={<Modal.Title className="mb-2">Add AWS Accounts</Modal.Title>}
      onClose={onClose}
      open
      size="2xl"
    >
      <form onSubmit={handleSubmitAddAccount} className="flex flex-col gap-3">
        <Fields>
          <Field label="Company Name" htmlFor="company-name">
            <Suspensed loading={companiesLoading} height="2.5rem">
              <Select
                id="company-name"
                onChange={handleChangeSelectedCompany}
                options={companyOptions}
                placeholder="Select Company"
                required
                value={selectedCompanyId}
              />
            </Suspensed>
          </Field>
          {accounts.map((account, index) => (
            <div
              className="grid grid-cols-[auto_2fr_3fr] gap-2"
              key={account.id}
            >
              <Field label="Payer?" htmlFor="is-payer">
                <div className="flex h-[36px] items-center justify-center">
                  <Checkbox
                    id="is-payer"
                    checked={account.isPayer}
                    onClick={() => handleChangeIsPayer(index, !account.isPayer)}
                  />
                </div>
              </Field>
              <Field
                label="Account ID"
                htmlFor="account-id"
                error={account.accountIdError}
                className="grow"
              >
                <Input
                  id="account-id"
                  maxLength={12}
                  minLength={12}
                  onChange={(e) => handleChangeAccountId(index, e.target.value)}
                  pattern="^\d{12}$"
                  placeholder="e.g. 123456789245"
                  required
                  rightIcon={account.isAccountIdValid && GreenCheckIcon}
                  title="Enter a valid AWS account ID."
                  type="text"
                  value={account.accountId}
                />
              </Field>
              <Field
                label="Account Name"
                htmlFor="account-name"
                flag="optional"
                className="grow"
              >
                <div className="flex grow gap-2">
                  <Input
                    container={{ className: "grow" }}
                    id="account-name"
                    placeholder="e.g. ABC Accs"
                    value={account.accountName}
                    onChange={(e) =>
                      handleChangeAccountName(index, e.target.value)
                    }
                  />
                  {accounts.length > 1 && (
                    <Button
                      kind="secondary"
                      size="small"
                      fill="none"
                      onClick={() => handleRemoveAccountForm(index)}
                    >
                      <TrashIcon className="h-2.5 w-2.5" />
                    </Button>
                  )}
                </div>
              </Field>
            </div>
          ))}
        </Fields>

        <Modal.Actions>
          <Buttons>
            <Button
              kind="primary"
              fill="none"
              onClick={handleAddAccountForm}
              leftIcon={PlusIcon}
            >
              Add Another
            </Button>
            <Button
              kind="primary"
              type="submit"
              loading={addAwsAccountLoading}
              disabled={isAddAccountDisabled}
            >
              Add {accounts.length > 1 ? `(${accounts.length})` : ""} Account
              {accounts.length > 1 ? "s" : ""}
            </Button>
          </Buttons>
        </Modal.Actions>
      </form>
    </Modal>
  );
};

const GreenCheckIcon = () => (
  <CheckIcon className="h-2.5 w-2.5 text-status-good" />
);

class AwsAccountForm {
  accountId: string;
  accountIdError: string;
  accountName: string;
  id: string;
  isAccountIdValid: boolean | null;
  isPayer: boolean;

  constructor() {
    this.accountId = "";
    this.accountIdError = "";
    this.accountName = "";
    this.id = generateUniqueId();
    this.isAccountIdValid = null;
    this.isPayer = false;
  }
}

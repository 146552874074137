import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

import { Tooltip } from "@m/ui";

interface SectionProps {
  children: ReactNode;
  title: string;
  description?: string;
  tooltip?: string;
  actions?: ReactNode;
}

export const Section = ({
  children,
  title,
  description,
  tooltip,
  actions,
}: SectionProps) => (
  <section className="flex flex-col gap-2 p-3">
    <div className="flex items-start justify-between gap-2">
      <div className="flex flex-col">
        <div className="flex items-center gap-1 text-base font-semibold">
          {title}
          {tooltip && (
            <Tooltip content={tooltip}>
              <InformationCircleIcon className="h-2.5 w-2.5 hover:cursor-help" />
            </Tooltip>
          )}
        </div>
        {description && (
          <div className="text-xs font-medium text-subdued">{description}</div>
        )}
      </div>
      {actions}
    </div>
    {children}
  </section>
);

import clsx from "clsx";

interface PlaceholderProps {
  opacity: number;
  loading: boolean;
}

export const Placeholder = ({ opacity, loading }: PlaceholderProps) => (
  <div
    data-testid={loading ? "placeholder-loading" : "placeholder"}
    style={{ opacity: loading ? 1 : opacity }}
    className={clsx("h-2 w-full rounded-full bg-gray-100", {
      "animate-pulse": loading,
    })}
  />
);

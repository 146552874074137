import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

export const LINK_COMPANY_ACCESS_HUB = gql(/* GraphQL */ `
  mutation LinkCompanyAccessHub($input: LinkCompanyAccessHubInput!) {
    linkCompanyAccessHub(input: $input) {
      ok
      message
    }
  }
`);

export const useLinkCompanyAccessHub = (companySlug: string) => {
  const [mutate, { data, ...result }] = useMutation(LINK_COMPANY_ACCESS_HUB, {
    onCompleted: () =>
      toast.success("The access hub application was linked successfully."),
    onError: () => toast.error("Unable to link the access hub application."),
    refetchQueries: ["GetCompanyAccessHub"],
    awaitRefetchQueries: true,
  });

  const linkCompanyAccessHub = (oktaAppId: string) =>
    mutate({ variables: { input: { companySlug, oktaAppId } } });

  const accessHubLinked = data?.linkCompanyAccessHub.ok;

  return [
    linkCompanyAccessHub,
    { data: { accessHubLinked }, ...result },
  ] as const;
};

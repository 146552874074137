import { ChangeEvent, useCallback } from "react";

import { IndeterminateCheckbox } from "../components";
import { TABLE_TITLES } from "../constants";

interface Row {
  original: Record<string, any>;
  getToggleRowSelectedProps: (props: any) => any;
  toggleRowSelected: (checked: boolean) => void;
}

interface UseSelectRowCheckboxProps {
  isAllRowsSelected: boolean;
  manualRowIdKey: string;
  selectedRowIds: string[];
  toggleRowSelected: (rowId: string, checked: boolean) => void;
  toggleRowSelectedTitle?: string;
}

interface UseSelectRowCheckboxReturn {
  renderSelectRowCheckbox: (props: { row: Row }) => JSX.Element;
}

export const useSelectRowCheckbox = ({
  isAllRowsSelected,
  manualRowIdKey,
  selectedRowIds,
  toggleRowSelected,
  toggleRowSelectedTitle = TABLE_TITLES.TOGGLE_ROW_SELECTED,
}: UseSelectRowCheckboxProps): UseSelectRowCheckboxReturn => {
  const renderSelectRowCheckbox = useCallback(
    ({ row }: { row: Row }) => {
      const isSelected =
        selectedRowIds && selectedRowIds.includes(row.original[manualRowIdKey]);

      let checked = isSelected;
      if (isAllRowsSelected) checked = !checked;

      return (
        <IndeterminateCheckbox
          {...row.getToggleRowSelectedProps({
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target;
              row.toggleRowSelected(checked);
              toggleRowSelected(row.original[manualRowIdKey], checked);
            },
            checked,
            title: toggleRowSelectedTitle,
          })}
        />
      );
    },
    [
      isAllRowsSelected,
      manualRowIdKey,
      selectedRowIds,
      toggleRowSelected,
      toggleRowSelectedTitle,
    ]
  );

  return {
    renderSelectRowCheckbox,
  };
};

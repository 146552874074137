import { useParams } from "react-router-dom";

import { PageTitle } from "@atlas/components";

import { OktaAccessHubSection } from "../components";

export const CustomerProfessionalServicesPage = () => {
  const { companySlug } = useParams<{ companySlug: string }>();

  return (
    <main className="flex flex-col gap-3">
      <PageTitle
        title="Professional Services"
        description="Manage the configuration of professional services for this customer"
      />
      <OktaAccessHubSection companySlug={companySlug} />
    </main>
  );
};
